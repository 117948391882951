import { decryptCustomerId } from '@amzn/amazon-id';

import { GetTitleDetailsQuery } from '../types/graphql/GeneratedTypes';

const T_CONST_REGEX = new RegExp(`tt[0-9]{7,19}`);

export const validateTconst = (tconst: string): boolean => T_CONST_REGEX.test(tconst);

export const validateCustomerId = (customerId: string): boolean => {
    try {
        decryptCustomerId(customerId);
        return true;
    } catch (e) {
        return false;
    }
};

export const isInvalidTitleDetailsQuery = (data: GetTitleDetailsQuery) => !data?.title?.titleText;

// Do not allow ',' or '|' in free text inputs
export const INVALID_FORM_INPUT_CHARACTERS_REGEX = new RegExp('^[^,|^|]+$');
