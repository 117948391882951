import * as React from 'react';

import Card, { CardHeader } from '@amzn/meridian/card';
import Divider from '@amzn/meridian/divider';
import Heading from '@amzn/meridian/heading';

export interface HomePageProps {
    service: string;
}

export const HomePage: React.FC<HomePageProps> = (props: HomePageProps) => {
    const { service } = props;

    return (
        <Card>
            <CardHeader>
                <Heading level={1}>{service}</Heading>
                <Divider />
            </CardHeader>
        </Card>
    );
};
