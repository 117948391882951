import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import TrustAdminApiFactory from '../../../trustAdmin-api/TrustAdminApiFactory';
import { INVALID_FORM_INPUT_CHARACTERS_REGEX } from '../../../utils/validation';
import { ListSpamWorkflowFormView } from './ListSpamWorkflowFormView';

export interface ListSpamWorkflowFormInput {
    ids?: string[];
}

export interface ListSpamWorkflowFormResponseData {
    fetching?: boolean;
    error?: AxiosError;
}

export const ListSpamWorkflowForm: React.VFC = () => {
    const navigate = useNavigate();
    const [inputData, setInputData] = useState<ListSpamWorkflowFormInput>({});
    const [responseData, setResponseData] = useState<ListSpamWorkflowFormResponseData>({ fetching: false });
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const validateFreeText = (customerIds: string[]) => !INVALID_FORM_INPUT_CHARACTERS_REGEX.test(customerIds.join(''));

    const validateInput = (input: ListSpamWorkflowFormInput) => {
        if (!input?.ids || input.ids.length === 0) {
            setResponseData({ error: new Error('Please ensure you have entered IDs to look up.') as AxiosError });
        } else if (validateFreeText(input.ids)) {
            setResponseData({
                error: new Error('Entered IDs do not match the correct format. Please fix.') as AxiosError
            });
        } else {
            setIsModalOpen(true);
        }
    };

    const onInputChange = (newData: object) => {
        setInputData({ ...inputData, ...newData });
    };

    const onSubmit = async () => {
        setIsModalOpen(false);
        setResponseData({ fetching: true });
        try {
            const response = await TrustAdminApiFactory().listsOwners.getListsOwners({
                ids: inputData.ids!
            });
            setResponseData({ fetching: false });

            navigate('/lists/results', {
                state: {
                    successMessage: 'Customer and List Ids submitted successfully!',
                    listsOwners: response.data
                }
            });
        } catch (e) {
            setResponseData({ error: e as AxiosError, fetching: false });
        }
    };

    return (
        <ListSpamWorkflowFormView
            inputData={inputData}
            onInputChange={onInputChange}
            responseData={responseData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSubmit={onSubmit}
            validateInput={validateInput.bind(this, inputData)}
        />
    );
};
