import * as React from 'react';

import { LoaderButton } from '@amzn/imdb-shared-meridian-components/components/LoaderButton';
import downloadSmallTokens from '@amzn/meridian-tokens/base/icon/download-small';
import Row from '@amzn/meridian/row';

interface Props {
    loading: boolean;
    errorMessage: string;
    fetch: () => Promise<any>;
}

export const RatingsExportActionBar: React.FC<Props> = (props) => {
    const { loading, errorMessage, fetch } = props;

    return (
        <Row>
            <LoaderButton
                loading={loading}
                id='downloadButton'
                text='Download CSV'
                onClick={fetch}
                iconProps={{ tokens: downloadSmallTokens }}
                errorMessage={errorMessage}
            />
        </Row>
    );
};
