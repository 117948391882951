import React, { useState } from 'react';
import { Outlet, useLocation, useSearchParams, useOutletContext, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CombinedError, useQuery } from 'urql';

import { SearchBar } from '@amzn/imdb-shared-meridian-components/components/SearchBar';

import { GetTitleDetailsQuery, GetTitleDetailsQueryVariables } from '../../../types/graphql/GeneratedTypes';
import { isInvalidTitleDetailsQuery, validateTconst } from '../../../utils/validation';
import { TITLE_SEARCH } from '../../components/AppNavigator/ProjectLinks';
import { TitleText } from './components/TitleText/TitleText';
import getTitleDetailsQuery from './queries/GetTitleDetailsQuery';

export type ContextType = {
    tconstInput: string;
    warning: string;
    titleDetailsQuery: TitleDetailsQuery;
};

export interface TitleDetailsQuery {
    data?: GetTitleDetailsQuery;
    fetching: boolean;
    error?: CombinedError;
}

const TCONST_PARAM = 'tconst';

export const TitleSearchLayout: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const getWarning = (searchInput: string, data?: GetTitleDetailsQuery) => {
        if (!searchInput) {
            return '';
        } else if (isInvalidSearchInput(searchInput, data)) {
            return `${searchInput} is not a valid tconst. Please try again.`;
        }
        return '';
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const searchInput = searchParams.get(TCONST_PARAM) || '';
    const [searchValue, setSearchValue] = useState<string>(searchInput);
    const [warning, setWarning] = useState<string>('');
    const [{ fetching, data, error }] = useQuery<GetTitleDetailsQuery, GetTitleDetailsQueryVariables>({
        query: getTitleDetailsQuery,
        variables: { tconst: searchInput }
    });

    const handleSearchSubmit = (value: string) => {
        setSearchParams({ [TCONST_PARAM]: value });
    };

    const handleSearchValueChange = (value: string) => {
        setSearchValue(value);
        navigate(TITLE_SEARCH.link + `?${TCONST_PARAM}=${value}`);
    };

    const isInvalidSearchInput = (searchInput: string, data?: GetTitleDetailsQuery): boolean =>
        !validateTconst(searchInput) || (!!data && (!data?.title || isInvalidTitleDetailsQuery(data)));

    React.useEffect(() => {
        setWarning(getWarning(searchInput, data));
    });

    return (
        <Container>
            {location.pathname === TITLE_SEARCH.link && (
                <SearchBar
                    id='searchBar'
                    label='Search for tconst'
                    value={searchValue}
                    searchButton={true}
                    warning={warning}
                    onChange={setSearchValue}
                    onSubmit={handleSearchSubmit}
                />
            )}
            {data && !isInvalidTitleDetailsQuery(data) && (
                <TitleText title={data?.title} setSearchValue={handleSearchValueChange} />
            )}
            <Outlet
                context={{
                    tconstInput: searchInput,
                    warning,
                    titleDetailsQuery: { fetching, data, error }
                }}
            />
        </Container>
    );
};

export function useTitleSearchContext() {
    return useOutletContext<ContextType>();
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
`;
