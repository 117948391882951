import { AxiosError } from 'axios';
import * as React from 'react';
import { CombinedError } from 'urql';

import Alert from '@amzn/meridian/alert';
import Card, { CardHeader } from '@amzn/meridian/card';
import Link from '@amzn/meridian/link';
import Text from '@amzn/meridian/text';

export interface RequestErrorCardProps {
    error: AxiosError | CombinedError;
}

export const RequestErrorCard: React.FC<RequestErrorCardProps> = (props: RequestErrorCardProps) => {
    const { error } = props;

    return (
        <Card>
            <CardHeader>
                <Alert type='error' title={getErrorTitle(error)} size='xlarge'>
                    {getErrorMessage(error)}
                </Alert>
            </CardHeader>
        </Card>
    );
};

const getErrorTitle = (error: AxiosError | CombinedError): string => {
    return error.response?.status === 403 ? 'Permission needed' : error.response?.data?.message || error.name;
};

const getErrorMessage = (error: AxiosError | CombinedError) =>
    error.response?.status === 403 ? (
        <Text>
            You do not currently have permission to this feature. Please see
            <Link
                href='https://t.corp.amazon.com/create/templates/5166fd18-fb76-40d1-af51-35e7656b66e5'
                target='_blank'
            >
                this link
            </Link>
            for details and to request access.
        </Text>
    ) : (
        error.message
    );
