import React from 'react';
import { useNavigate } from 'react-router-dom';

import arrowLeftLargeTokens from '@amzn/meridian-tokens/base/icon/arrow-left-large';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';

import { RatingsExportDataTable } from '../../../features/RatingsExportDataTable/RatingsExportDataTable';
import { useTitleSearchContext } from '../TitleSearchLayout';

export const RatingsExport: React.VFC = () => {
    const { tconstInput, warning } = useTitleSearchContext();
    const navigate = useNavigate();

    return (
        <>
            <Button href={`/titleSearch?tconst=${tconstInput}`} onClick={navigate}>
                <Icon tokens={arrowLeftLargeTokens} />
            </Button>
            <RatingsExportDataTable tconst={tconstInput} warning={warning} />
        </>
    );
};
