import { AxiosError } from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import { TitleDetailsResponse } from '../../../trustAdmin-api/generated-src';
import TrustAdminApiFactory from '../../../trustAdmin-api/TrustAdminApiFactory';
import { isInvalidTitleDetailsQuery } from '../../../utils/validation';
import { TitleDetailsQuery } from '../../pages/titleSearch/TitleSearchLayout';
import { GenericErrorCard } from '../error/GenericErrorCard';
import { RequestErrorCard } from '../error/RequestErrorCard';
import { RatingDetailsTable } from './components/RatingDetailsTable/RatingDetailsTable';
import { RrcDetailsTable } from './components/RrcDetailsTable/RrcDetailsTable';
import { TitleDetailsTable } from './components/TitleDetailsTable/TitleDetailsTable';
import { TitleRatingsSummary } from './components/TitleRatingsSummary/TitleRatingsSummary';

export interface TitleDetailsSectionProps {
    searchValue: string;
    titleDetailsQuery: TitleDetailsQuery;
}

interface TitleDetails {
    data?: TitleDetailsResponse;
    fetching: boolean;
    error?: AxiosError;
}

export const TitleDetailsSection: React.FC<TitleDetailsSectionProps> = (props: TitleDetailsSectionProps) => {
    const { searchValue, titleDetailsQuery } = props;
    const navigate = useNavigate();

    const [titleDetails, setTitleDetails] = React.useState<TitleDetails>({ fetching: true });

    React.useEffect(() => {
        const fetchTitleDetails = async (tconst: string) => {
            try {
                setTitleDetails({ fetching: true });
                const response = await TrustAdminApiFactory().titleDetails.titleDetails(tconst);
                setTitleDetails({ data: response.data, fetching: false });
            } catch (e) {
                setTitleDetails({ fetching: false, error: e as AxiosError });
            }
        };
        fetchTitleDetails(searchValue);
    }, [searchValue]);

    const isLoading = () => {
        return titleDetailsQuery.fetching || titleDetails.fetching;
    };

    const isError = () => {
        return titleDetailsQuery.error || isInvalidTitleDetailsQuery(titleDetailsQuery.data!) || titleDetails.error;
    };

    const showLoader = () => {
        return (
            <>
                <Loader />
                <Text>{`Loading ${searchValue}...`}</Text>
            </>
        );
    };

    const showError = () => {
        if (titleDetailsQuery.error) {
            return <RequestErrorCard error={titleDetailsQuery.error} />;
        } else if (titleDetails.error) {
            return <RequestErrorCard error={titleDetails.error} />;
        } else {
            return <GenericErrorCard message={`${searchValue} is not a valid tconst. Please try again.`} />;
        }
    };

    const showTitleDetails = () => {
        return (
            <TitleDetailsContainer>
                <Row alignmentHorizontal='end'>
                    <Button href={`/titleSearch/ratingsExport?tconst=${searchValue}`} onClick={navigate}>
                        See title ratings
                    </Button>
                </Row>
                {titleDetails.data && (
                    <>
                        <TitleRatingsSummary
                            title={titleDetailsQuery.data?.title}
                            ratingsDetails={titleDetails.data.ratingsDetails}
                        />
                        <RatingDetailsTable
                            ratingsCount={titleDetails.data.ratingsDetails.ratingsCount}
                            standardDeviation={titleDetails.data.ratingsDetails.standardDeviation}
                        />
                        <TitleDetailsTable
                            title={titleDetailsQuery.data?.title}
                            chartSummary={titleDetails.data.ratingsDetails.charts}
                        />
                        <RrcDetailsTable revisedRatings={titleDetails.data.ratingsDetails.revisedRatings} />
                    </>
                )}
            </TitleDetailsContainer>
        );
    };

    return <Container>{(isLoading() && showLoader()) || (isError() && showError()) || showTitleDetails()}</Container>;
};

const TitleDetailsContainer = styled.div`
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: scroll;
`;

const Container = styled.div`
    text-align: center;
    padding: 15px 0px;
`;
