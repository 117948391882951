import React from 'react';

import Heading from '@amzn/meridian/heading';

import { ListSpamWorkflowForm } from '../../../../features/ListSpamWorkflowForm/ListSpamWorkflowForm';

export const ListSpamWorkflowSearch: React.VFC = () => {
    return (
        <>
            <Heading level={1}>List Spam Workflow</Heading>
            <ListSpamWorkflowForm />
        </>
    );
};
