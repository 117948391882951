import * as React from 'react';
import styled from 'styled-components';

import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Table, { TableCell, TableRow, TableSectionHeader } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';

import {
    SupportedChartType,
    TitleDetailsResponseRatingsDetails
} from '../../../../../trustAdmin-api/generated-src/api';
import { GetTitleDetailsQuery } from '../../../../../types/graphql/GeneratedTypes';
import {
    getChartMetadata,
    getCountriesOfOrigin,
    getDateString,
    getEpisodeString,
    isValidChart
} from '../../utils/getTitleDetails';

type FieldName = 'tconst' | 'Title type' | 'Episode' | 'Release date' | 'Country of origin' | 'Appears on';

export type ChartInformation = { [key in SupportedChartType]: ChartMetadata };

export interface ChartMetadata {
    name: string;
    link: string;
    maxRank: number;
}

export interface TitleDetailsTableProps {
    title: GetTitleDetailsQuery['title'];
    chartSummary: TitleDetailsResponseRatingsDetails['charts'];
}

export const TitleDetailsTable: React.FC<TitleDetailsTableProps> = (props: TitleDetailsTableProps) => {
    const { title, chartSummary } = props;

    const titleDetailsTableRows = createTableRows(title, chartSummary);
    return (
        <Table data-test-id='titleDetailsTable' showDividers={true} spacing='small'>
            <TableSectionHeader>Title details</TableSectionHeader>
            {titleDetailsTableRows.map((row) => (
                <TableRow key={row.name}>
                    <TableCell width='25%'>
                        <Text type='h200'>{row.name}</Text>
                    </TableCell>
                    <CustomTableCell name={row.name} data={row.data} chartSummary={chartSummary} />
                </TableRow>
            ))}
        </Table>
    );
};

interface CustomTableRowProps<T extends string> {
    name: T;
    data?: string;
    title?: GetTitleDetailsQuery['title'];
    chartSummary?: TitleDetailsResponseRatingsDetails['charts'];
}

const CustomTableCell: React.VFC<CustomTableRowProps<FieldName>> = (props) => {
    const { name, data, chartSummary } = props;
    switch (name) {
        case 'tconst':
            return <LinkTableCell data={data} link={`https://imdb.com/title/${data}`} />;
        case 'Appears on': {
            const charts = chartSummary!
                .filter((chart) => isValidChart(chart))
                .map((chart) => {
                    const chartMetadata = getChartMetadata(chart);
                    return (
                        <TopChartTableCellRow
                            key={chartMetadata.name}
                            chartName={chartMetadata.name}
                            chartLink={chartMetadata.link}
                            chartRating={chart.rating}
                            chartRank={chart.position}
                        />
                    );
                });
            return charts.length > 0 ? (
                <Column spacingInset='none' spacing='none'>
                    {charts}
                </Column>
            ) : (
                <DefaultTableCell data='No charts' />
            );
        }
        default:
            return <DefaultTableCell data={data} />;
    }
};

const DefaultTableCell = ({ data }) => (
    <TableCell>
        <Text type='b400'>{data}</Text>
    </TableCell>
);

const LinkTableCell = ({ data, link }) => (
    <TableCell>
        <Link type='secondary' href={link} target='_blank'>
            {data!}
            <IconContainer>
                <Icon tokens={exportSmallTokens} />
            </IconContainer>
        </Link>
    </TableCell>
);

const TopChartTableCellRow = ({ chartName, chartRank, chartRating, chartLink }) => (
    <Row spacingInset='none' spacing='none'>
        <TableCell width='33%'>
            <Column spacing='xsmall'>
                <Link type='secondary' href={chartLink} target='_blank'>
                    {chartName}
                    <IconContainer>
                        <Icon tokens={exportSmallTokens} />
                    </IconContainer>
                </Link>
                Chart name
            </Column>
        </TableCell>
        <TableCell width='33%'>
            <Text type='b400'>{chartRating.toFixed(1)}</Text>
            Chart rating
        </TableCell>
        <TableCell width='33%'>
            <Text type='b400'>{chartRank}</Text>
            Chart position
        </TableCell>
    </Row>
);

const createTableRows = (
    title: GetTitleDetailsQuery['title'],
    chartSummary: TitleDetailsResponseRatingsDetails['charts']
): CustomTableRowProps<FieldName>[] => {
    const rows: CustomTableRowProps<FieldName>[] = [
        {
            name: 'tconst',
            data: title?.id
        },
        {
            name: 'Title type',
            data: title?.titleType?.text
        },
        {
            name: 'Release date',
            data: getDateString(title)
        },
        {
            name: 'Country of origin',
            data: getCountriesOfOrigin(title)
        }
    ];
    if (title?.series?.episodeNumber) {
        rows.splice(2, 0, {
            name: 'Episode',
            data: getEpisodeString(title)
        });
    }
    if (chartSummary) {
        rows.push({
            name: 'Appears on',
            chartSummary: chartSummary
        });
    }
    return rows;
};

const IconContainer = styled.span`
    margin-left: 4px;
`;
