import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import orderBy from 'lodash/orderBy';
import React from 'react';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    Header,
    OnSortArgs,
    Sort
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import Link from '@amzn/meridian/link';
import { TableActionBarProps } from '@amzn/meridian/table-action-bar/table-action-bar';
import { TableSortDirection } from '@amzn/meridian/table/table';

import { RatingsExportRow, JobStatus } from '../../../trustAdmin-api/generated-src';
import TrustAdminApiFactory from '../../../trustAdmin-api/TrustAdminApiFactory';
import { delay } from '../../../utils/delay';
import { translateToLodashSortDirection } from '../../../utils/sort';
import { GenericErrorCard } from '../error/GenericErrorCard';
import { RequestErrorCard } from '../error/RequestErrorCard';
import { RatingsExportActionBar } from './components/RatingsExportActionBar';

export interface RatingsExportDataTableProps {
    tconst: string;
    warning?: string;
}

export type ColumnId = keyof RatingsExportRow;

export const RatingsExportDataTable: React.FC<RatingsExportDataTableProps> = (props: RatingsExportDataTableProps) => {
    const { tconst, warning } = props;
    const [sortColumn, setSortColumn] = React.useState<ColumnId>();
    const [sortDirection, setSortDirection] = React.useState<TableSortDirection>();

    const [exportStatus, setExportStatus] = React.useState<JobStatus>(JobStatus.Queued);
    const [ratingsExport, setRatingsExport] = React.useState<RatingsExportRow[]>([]);
    const [lastBuildDateTime, setLastBuildDateTime] = React.useState<string>('');
    const [downloadUrl, setDownloadUrl] = React.useState<string>();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<any>(null);

    const [isExportLoading, setIsExportLoading] = React.useState<boolean>(false);
    const [exportErrorMessage, setExportErrorMessage] = React.useState<string>('');

    const onSort = (args: OnSortArgs<ColumnId>) => {
        setSortColumn(args.sortColumn);
        setSortDirection(args.sortDirection);
        setRatingsExport(
            orderBy(ratingsExport, [args.sortColumn], [translateToLodashSortDirection(args.sortDirection)])
        );
    };

    const sort: Sort<ColumnId> = {
        column: sortColumn,
        direction: sortDirection,
        onSort: onSort
    };

    React.useEffect(() => {
        async function requestRatingsExport() {
            try {
                const response = await TrustAdminApiFactory().titleRatingsExportRequest.titleRatingsExportRequest(
                    tconst
                );
                return response.data.exportToken;
            } catch (e: any) {
                setError(e);
            }
        }
        async function fetchRatingsExport(exportToken: string) {
            try {
                let status = exportStatus;
                outer: while (status === JobStatus.Queued || status === JobStatus.Running) {
                    const response = await TrustAdminApiFactory().titleRatingsExportStatus.titleRatingsExportStatus(
                        exportToken,
                        250
                    );
                    setExportStatus(response.data.status);
                    if (response.data?.lastBuildDateTime) {
                        setLastBuildDateTime(response.data.lastBuildDateTime);
                    }
                    switch (response.data.status) {
                        case JobStatus.Succeeded:
                            response.data.ratings?.rows && setRatingsExport(response.data.ratings?.rows);
                            setDownloadUrl(response.data.downloadUrl);
                            break outer;
                        case JobStatus.Failed:
                        case JobStatus.Cancelled:
                            throw new Error(
                                `Ratings export for ${tconst} has ${response.data.status}. Please try again.`
                            ) as AxiosError;
                    }
                    status = response.data.status;
                    await delay(5000);
                }
            } catch (e: any) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
        requestRatingsExport().then((exportToken) => fetchRatingsExport(exportToken!));
    }, []);

    async function fetchRatingsExportDataExport() {
        setExportErrorMessage('');
        setIsExportLoading(true);
        try {
            const logResponse = await TrustAdminApiFactory().titleRatingsExportRequest.titleRatingsExportRequestLog({
                tconst,
                lastBuildDateTime
            });
            if (logResponse.data.succeeded) {
                fetch(downloadUrl!)
                    .then((res) => res.blob())
                    .then((blob) => {
                        saveAs(blob, `${tconst}-ratings-export${lastBuildDateTime ? `_${lastBuildDateTime}` : ''}.csv`);
                    });
            }
        } catch (e: any) {
            setExportErrorMessage(e.message);
        } finally {
            setIsExportLoading(false);
        }
    }

    const isError = () => warning || error;

    const showError = () => {
        if (warning) {
            return <GenericErrorCard message={warning} />;
        } else {
            return <RequestErrorCard error={error} />;
        }
    };

    const showTable = () => (
        <DataTable
            data={createTablesData(ratingsExport)}
            isLoading={isLoading}
            loadingMessage={`Ratings export query is ${exportStatus}...`}
            tableProps={createTableProps()}
            actionBarProps={createActionBarProps(isExportLoading, exportErrorMessage, fetchRatingsExportDataExport)}
            sort={sort}
            CustomRowTableCell={CustomRowTableCell}
        />
    );

    return (isError() && showError()) || showTable();
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'customerId':
            return <UserAdminUserDetailsLink customerId={row['customerId']} />;
        case 'isShadowbanned':
        case 'isStaff':
            return <BooleanValueCellContent data={row[headerId] as boolean} />;
        case 'numRatings':
        case 'r_1':
        case 'r_2':
        case 'r_3':
        case 'r_4':
        case 'r_5':
        case 'r_6':
        case 'r_7':
        case 'r_8':
        case 'r_9':
        case 'r_10':
            return <NumberValueCellContent data={row[headerId] as number} />;
        default:
            return <DefaultTableCellContent data={row[headerId]} />;
    }
};

const UserAdminUserDetailsLink = ({ customerId }) => (
    <Link href={`https://imdb-useradmin-iad.iad.proxy.amazon.com/?customerid=${customerId}`} target='_blank'>
        {customerId}
    </Link>
);
const BooleanValueCellContent = ({ data }: { data: boolean }) => <>{data ? 'Yes' : 'No'}</>;
const NumberValueCellContent = ({ data }: { data: number }) => <>{data.toLocaleString()}</>;
const DefaultTableCellContent = ({ data }) => <>{data !== null ? data : 'N/A'}</>;

const createTablesData = (ratingsExport: RatingsExportRow[]): Data<ColumnId> => {
    return {
        headers: createHeaders(),
        rows: ratingsExport
    };
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'customerId',
            label: 'CustomerId'
        },
        {
            id: 'rating',
            label: 'Rating'
        },
        {
            id: 'dateRated',
            label: 'Date rated'
        },
        {
            id: 'signupDatetime',
            label: 'Acct created'
        },
        {
            id: 'timediffHours',
            label: 'Diff in Hours'
        },
        {
            id: 'isShadowbanned',
            label: 'Shadowbanned'
        },
        {
            id: 'countryCode',
            label: 'Country of origin'
        },
        {
            id: 'weight',
            label: 'Acct weight'
        },
        {
            id: 'numRatings',
            label: 'Total ratings'
        },
        {
            id: 'rAvg',
            label: 'Ratings avg'
        },
        {
            id: 'r_1',
            label: 'r_1'
        },
        {
            id: 'r_2',
            label: 'r_2'
        },
        {
            id: 'r_3',
            label: 'r_3'
        },
        {
            id: 'r_4',
            label: 'r_4'
        },
        {
            id: 'r_5',
            label: 'r_5'
        },
        {
            id: 'r_6',
            label: 'r_6'
        },
        {
            id: 'r_7',
            label: 'r_7'
        },
        {
            id: 'r_8',
            label: 'r_8'
        },
        {
            id: 'r_9',
            label: 'r_9'
        },
        {
            id: 'r_10',
            label: 'r_10'
        },
        {
            id: 'emailDomain',
            label: 'Email Domain'
        },
        {
            id: 'emailDomainObfuscated',
            label: 'Email Domain Obfuscated'
        }
    ];
};

const createTableProps = () => {
    return {
        stickyHeaderRow: true,
        stickyHeaderColumn: true,
        headerColumns: 1
    };
};

const createActionBarProps = (
    loading: boolean,
    errorMessage: string,
    fetch: () => Promise<any>
): TableActionBarProps => {
    return {
        children: <RatingsExportActionBar loading={loading} errorMessage={errorMessage} fetch={fetch} />
    };
};
