import * as React from 'react';

import {
    DetailsTableCell,
    DetailsTableCellData
} from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableCell';
import {
    CellHeaders,
    CustomDetailsTableRow
} from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableRow';
import Alert from '@amzn/meridian/alert';
import Table, { TableSectionHeader } from '@amzn/meridian/table';

import {
    TitleDetailsResponseRatingsDetails,
    TitleDetailsResponseRatingsDetailsRatingsCount,
    TitleDetailsResponseRatingsDetailsStandardDeviation
} from '../../../../../trustAdmin-api/generated-src';
import { MISSING_VALUE } from '../../consts';

type BuildChangeCellId = 'build_change' | 'day_change' | 'week_change';

const BUILD_CHANGE_CELL_HEADERS: CellHeaders<BuildChangeCellId> = {
    build_change: {
        id: 'build_change',
        label: 'Build change %'
    },
    day_change: {
        id: 'day_change',
        label: 'Day change %'
    },
    week_change: {
        id: 'week_change',
        label: 'Week change %'
    }
};

type RatingDetailsTableProps = Partial<Pick<TitleDetailsResponseRatingsDetails, 'ratingsCount' | 'standardDeviation'>>;

const TABLE_HEADER = 'Rating details';
const STANDARD_DEVIATION_ROW_HEADER = 'Standard deviation %';
const VOTE_COUNT_ROW_HEADER = 'Vote counts %';
const ERROR_ALERT_MESSAGE = 'Error rendering Rating details.';

export const RatingDetailsTable: React.FC<RatingDetailsTableProps> = (props) => {
    const { ratingsCount, standardDeviation } = props;

    return (
        <>
            <Table
                data-test-id='ratingDetailsTable'
                showDividers={true}
                spacing='small'
                rowComponents={[CustomDetailsTableRow]}
            >
                <TableSectionHeader>{TABLE_HEADER}</TableSectionHeader>
                {standardDeviation && (
                    <CustomDetailsTableRow
                        header={STANDARD_DEVIATION_ROW_HEADER}
                        cellHeaders={BUILD_CHANGE_CELL_HEADERS}
                        data={standardDeviation}
                        getTableCell={getStandardDeviationTableCell}
                    />
                )}
                {ratingsCount && (
                    <CustomDetailsTableRow
                        header={VOTE_COUNT_ROW_HEADER}
                        cellHeaders={BUILD_CHANGE_CELL_HEADERS}
                        data={ratingsCount}
                        getTableCell={getVoteCountTableCell}
                    />
                )}
            </Table>
            {(!standardDeviation || !ratingsCount) && <ErrorAlert />}
        </>
    );
};

const ErrorAlert: React.FC = () => (
    <Alert type='error' size='large'>
        {ERROR_ALERT_MESSAGE}
    </Alert>
);

const getStandardDeviationTableCell = (
    cellId: BuildChangeCellId,
    standardDeviation: TitleDetailsResponseRatingsDetailsStandardDeviation
) => {
    const tableCell = (data: DetailsTableCellData | null) => {
        const detailsTableCellData = data !== null ? data : MISSING_VALUE;
        return (
            <DetailsTableCell
                key={cellId}
                data={detailsTableCellData}
                subHeader={BUILD_CHANGE_CELL_HEADERS[cellId].label}
                width='25%'
            />
        );
    };
    switch (cellId) {
        case 'build_change': {
            return tableCell(standardDeviation.buildPercentageChange);
        }
        case 'day_change': {
            return tableCell(standardDeviation.dayPercentageChange);
        }
        case 'week_change': {
            return tableCell(standardDeviation.weekPercentageChange);
        }
    }
};

const getVoteCountTableCell = (
    cellId: BuildChangeCellId,
    ratingsCount: TitleDetailsResponseRatingsDetailsRatingsCount
) => {
    const tableCell = (data: DetailsTableCellData | null) => {
        const detailsTableCellData = data !== null ? data : MISSING_VALUE;
        return (
            <DetailsTableCell
                key={cellId}
                data={detailsTableCellData}
                subHeader={BUILD_CHANGE_CELL_HEADERS[cellId].label}
                width='25%'
            />
        );
    };
    switch (cellId) {
        case 'build_change': {
            return tableCell(ratingsCount.buildPercentageChange);
        }
        case 'day_change': {
            return tableCell(ratingsCount.dayPercentageChange);
        }
        case 'week_change': {
            return tableCell(ratingsCount.weekPercentageChange);
        }
    }
};
