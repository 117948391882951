import { ChartInformation } from './components/TitleDetailsTable/TitleDetailsTable';

export const MISSING_VALUE = 'N/A';

export const CHART_INFORMATION: ChartInformation = {
    Top: {
        name: 'IMDb Top 250 Movies',
        link: 'https://www.imdb.com/chart/top',
        maxRank: 250
    },
    TopTv: {
        name: 'Top Rated TV Shows',
        link: 'https://www.imdb.com/chart/toptv',
        maxRank: 250
    },
    IndiaTop: {
        name: 'Top Rated Indian Movies',
        link: 'https://www.imdb.com/india/top-rated-indian-movies',
        maxRank: 250
    },
    EnglishTop: {
        name: 'Top Rated English Movies',
        link: 'https://www.imdb.com/chart/top-english-movies',
        maxRank: 250
    },
    MalayalamTop: {
        name: 'Top Rated Malayalam Movies',
        link: 'https://www.imdb.com/india/top-rated-malayalam-movies',
        maxRank: 50
    },
    TamilTop: {
        name: 'Top Rated Tamil Movies',
        link: 'https://www.imdb.com/india/top-rated-tamil-movies',
        maxRank: 50
    },
    TeluguTop: {
        name: 'Top Rated Telugu Movies',
        link: 'https://www.imdb.com/india/top-rated-telugu-movies',
        maxRank: 50
    },
    Bottom: {
        name: 'Lowest Rated Movies',
        link: 'https://www.imdb.com/chart/bottom',
        maxRank: 100
    }
};
