import React from 'react';

import Heading from '@amzn/meridian/heading';

import { SuspiciousTitlesDataTable } from '../../../features/SuspiciousTitlesDataTable/SuspiciousTitlesDataTable';

export const SuspiciousTitles: React.VFC = () => {
    return (
        <>
            <Heading level={1}>Suspicious Titles</Heading>
            <SuspiciousTitlesDataTable />
        </>
    );
};
