import { saveAs } from 'file-saver';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    Header,
    OnSortArgs,
    Sort
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import Link from '@amzn/meridian/link';
import { TableActionBarProps } from '@amzn/meridian/table-action-bar/table-action-bar';
import { TableSortDirection } from '@amzn/meridian/table/table';

import { SuspiciousTitle } from '../../../trustAdmin-api/generated-src';
import TrustAdminApiFactory from '../../../trustAdmin-api/TrustAdminApiFactory';
import { translateToLodashSortDirection } from '../../../utils/sort';
import { ActionBar } from '../../components/ActionBar/ActionBar';
import { RequestErrorCard } from '../error/RequestErrorCard';

export type ColumnId =
    | 'tconst'
    | 'titleText'
    | 'buildChangeCount'
    | 'dayChangeCount'
    | 'weekChangeCount'
    | 'buildChangeStddev'
    | 'dayChangeStddev'
    | 'weekChangeStddev'
    | 'titleCount';

export const SuspiciousTitlesDataTable: React.FC = () => {
    const [suspiciousTitles, setSuspiciousTitles] = React.useState<SuspiciousTitle[]>([]);
    const [numUniqueTitles, setNumUniqueTitles] = React.useState('0');
    const [lastBuildDateTime, setLastBuildDateTime] = React.useState('');
    const [sortColumn, setSortColumn] = React.useState<ColumnId>();
    const [sortDirection, setSortDirection] = React.useState<TableSortDirection>();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<any>(null);

    const [isExportLoading, setIsExportLoading] = React.useState<boolean>(false);
    const [exportErrorMessage, setExportErrorMessage] = React.useState<string>('');

    const onSort = (args: OnSortArgs<ColumnId>) => {
        setSortColumn(args.sortColumn);
        setSortDirection(args.sortDirection);
        setSuspiciousTitles(
            orderBy(suspiciousTitles, [args.sortColumn], [translateToLodashSortDirection(args.sortDirection)])
        );
    };

    const sort: Sort<ColumnId> = {
        column: sortColumn,
        direction: sortDirection,
        onSort: onSort
    };

    React.useEffect(() => {
        async function fetchSuspiciousTitles() {
            try {
                const response = await TrustAdminApiFactory().suspiciousTitlesOutput.listSuspiciousTitles();
                setSuspiciousTitles(response.data.suspiciousTitles);
                setNumUniqueTitles(response.data.numUniqueTitles.toString());
                if (response.data.lastBuildDateTime != undefined) {
                    setLastBuildDateTime(response.data.lastBuildDateTime);
                }
            } catch (e: any) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
        fetchSuspiciousTitles();
    }, []);

    async function fetchSuspiciousTitlesDataExport() {
        setExportErrorMessage('');
        setIsExportLoading(true);
        try {
            const response = await TrustAdminApiFactory().suspiciousTitlesExportData.suspiciousTitlesExportData();
            saveAs(response.data.downloadUrl);
        } catch (e: any) {
            setExportErrorMessage(e.message);
        } finally {
            setIsExportLoading(false);
        }
    }

    const showError = () => <RequestErrorCard error={error} />;

    const showTable = () => (
        <DataTable
            data={createTablesData(suspiciousTitles)}
            isLoading={isLoading}
            actionBarProps={createActionBarProps(
                isExportLoading,
                exportErrorMessage,
                fetchSuspiciousTitlesDataExport,
                'Title Count: ' + numUniqueTitles,
                'Last Build DateTime: ' + lastBuildDateTime + ' UTC'
            )}
            sort={sort}
            CustomRowTableCell={CustomRowTableCell}
        />
    );

    return (error && showError()) || showTable();
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'tconst':
            return <IMDbTitleMainDetailsLink tconst={row['tconst']} />;
        case 'titleText':
            return <RouterLink to={`/titleSearch?tconst=${row['tconst']}`}>{row['titleText']}</RouterLink>;
        default:
            return <DefaultTableCellContent data={row[headerId]} />;
    }
};

const IMDbTitleMainDetailsLink = ({ tconst }) => (
    <Link href={`https://www.imdb.com/title/${tconst}/`} target='_blank'>
        {tconst}
    </Link>
);
const DefaultTableCellContent = ({ data }) => <>{data}</>;

const createTablesData = (suspiciousTitles: SuspiciousTitle[]): Data<ColumnId> => {
    return {
        headers: createHeaders(),
        rows: suspiciousTitles
    };
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'tconst',
            label: 'tconst'
        },
        {
            id: 'titleText',
            label: 'Title'
        },
        {
            id: 'buildChangeStddev',
            label: 'Build Change Standard Deviation %'
        },
        {
            id: 'dayChangeStddev',
            label: 'Day Change Standard Deviation %'
        },
        {
            id: 'weekChangeStddev',
            label: 'Week Change Standard Deviation %'
        },
        {
            id: 'buildChangeCount',
            label: 'Build Change Vote Counts %'
        },
        {
            id: 'dayChangeCount',
            label: 'Day Change Vote Counts %'
        },
        {
            id: 'weekChangeCount',
            label: 'Week Change Vote Counts %'
        },
        {
            id: 'titleCount',
            label: 'Title Appearance Count'
        }
    ];
};

const createActionBarProps = (
    loading: boolean,
    errorMessage: string,
    fetch: () => Promise<any>,
    titleCount: string,
    lastBuildDateTime: string
): TableActionBarProps => {
    return {
        children: (
            <ActionBar
                fetch={fetch}
                lastBuildDateTime={lastBuildDateTime}
                titleCount={titleCount}
                loading={loading}
                errorMessage={errorMessage}
            />
        )
    };
};
