import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { DetailsCard, DetailsCardProps } from '@amzn/imdb-shared-meridian-components/components/DetailsCard';
import { GenericErrorCard } from '@amzn/imdb-shared-meridian-components/components/error/GenericErrorCard';
import filterTokens from '@amzn/meridian-tokens/base/icon/filter';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import Divider from '@amzn/meridian/divider';
import Icon from '@amzn/meridian/icon';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import { FrequentlyUsedText, UserActivityItem } from '../../../trustAdmin-api/generated-src';
import { daysFromToday, formatIso } from '../../../utils/date';
import { ListsTextItemsDataTable, SelectedTextItems } from './components/ListsTextItemsDataTable';
import { ListsUserActivityDataTable, SelectedCustomers } from './components/ListsUserActivityDataTable';
import { ListsTextSearchFormInput, ListsTextSearchFormResponseData } from './ListsTextSearchForm';

type LeftColumnCardId = 'Text Items';
type RightColumnCardId = 'Users';

interface ListsTextSearchFormViewProps {
    inputData: ListsTextSearchFormInput;
    onInputChange: (_: ListsTextSearchFormInput) => void;
    selectedTextItems: SelectedTextItems;
    setSelectedTextItems: Dispatch<SetStateAction<SelectedTextItems>>;
    selectedCustomers: SelectedCustomers;
    setSelectedCustomers: Dispatch<SetStateAction<SelectedCustomers>>;
    responseData: ListsTextSearchFormResponseData;
    onSubmit: () => void;
    canLoadMore: boolean;
    onLoadMore: () => void;
    fetchingCustomerIds: boolean;
    onAnalyzeSelection: () => void;
}

export const ListsTextSearchFormView: React.VFC<ListsTextSearchFormViewProps> = (props) => {
    const {
        inputData,
        onInputChange,
        selectedTextItems,
        setSelectedTextItems,
        selectedCustomers,
        setSelectedCustomers,
        responseData,
        onSubmit,
        canLoadMore,
        onLoadMore,
        fetchingCustomerIds,
        onAnalyzeSelection
    } = props;

    const setDates = (dates: string[]) => {
        onInputChange({ startDate: dates[0], endDate: dates[1] });
    };

    const setShowSpam = (showSpam: boolean) => {
        onInputChange({ showSpam });
    };

    const renderSearch = () => {
        const today = formatIso(new Date());
        const threeDaysAgo = formatIso(daysFromToday(-2));
        const sevenDaysAgo = formatIso(daysFromToday(-6));

        return (
            <VerticalSpacer>
                <Column>
                    <Row
                        width='100%'
                        widths={['300px', '400px', '200px', '150px', 'fill']}
                        spacing='400'
                        alignmentVertical='bottom'
                    >
                        <Text type='h100'>Look at regular lists modified between</Text>
                        <DateRangePicker
                            value={[inputData.startDate!, inputData.endDate!]}
                            onChange={setDates}
                            startLabel='Start date'
                            endLabel='End date'
                            monthsInView={2}
                            presets={[
                                { label: 'Today', value: [today, today] },
                                { label: 'Last 3 days', value: [threeDaysAgo, today] },
                                { label: 'Last 7 days', value: [sevenDaysAgo, today] }
                            ]}
                        />
                        <HorizontalSpacer style={{ paddingLeft: '25px' }}>
                            <Checkbox checked={inputData.showSpam} onChange={setShowSpam}>
                                <Text type='h100'>Include SPAM</Text>
                            </Checkbox>
                        </HorizontalSpacer>
                        <Button onClick={onSubmit}>Find lists</Button>
                        {Object.keys(selectedTextItems).filter((k) => selectedTextItems[k]).length ||
                        Object.keys(selectedCustomers).filter((k) => selectedCustomers[k]).length ? (
                            <HorizontalSpacer style={{ textAlign: 'right' }}>
                                {fetchingCustomerIds ? (
                                    <HorizontalSpacer
                                        role='selection-loader'
                                        style={{ width: '200px', display: 'inline-block' }}
                                    >
                                        <Loader size='small' type='linear'></Loader>
                                    </HorizontalSpacer>
                                ) : (
                                    <Button type='tertiary' onClick={onAnalyzeSelection}>
                                        <Icon tokens={filterTokens} /> Analyze selection
                                    </Button>
                                )}
                            </HorizontalSpacer>
                        ) : null}
                    </Row>
                    {canLoadMore ? (
                        <Alert>
                            <Row spacing='300' width='330px'>
                                <Text type='h100'>There are more items to load.</Text>
                                <Button size='small' type='tertiary' onClick={onLoadMore}>
                                    Load More
                                </Button>
                            </Row>
                        </Alert>
                    ) : null}
                </Column>
            </VerticalSpacer>
        );
    };

    const renderLoader = () => {
        return (
            <VerticalSpacer style={{ paddingTop: '100px' }}>
                <Row alignmentHorizontal='center' widths={['200px']}>
                    <Column>
                        <Text alignment='center' type='h200'>
                            Fetching lists...
                        </Text>
                        <Loader size='small' type='linear' />
                    </Column>
                </Row>
            </VerticalSpacer>
        );
    };

    const renderError = () => {
        return <GenericErrorCard message={responseData.error?.message ?? 'Unexpected error'} />;
    };

    const createLeftColumnCards = (
        textItems: FrequentlyUsedText[],
        selectedTextItems: SelectedTextItems
    ): DetailsCardProps<LeftColumnCardId>[] => [
        {
            name: 'Text Items',
            CustomCardContentElement: (
                <Column>
                    <ListsTextItemsDataTable
                        textItems={textItems}
                        selectableOptions={{
                            idFieldName: 'text',
                            selected: selectedTextItems,
                            setSelected: setSelectedTextItems
                        }}
                    />
                </Column>
            )
        }
    ];

    const createRightColumnCards = (
        userActivity: UserActivityItem[],
        selectedCustomers: SelectedCustomers
    ): DetailsCardProps<RightColumnCardId>[] => [
        {
            name: 'Users',
            CustomCardContentElement: (
                <Column>
                    <ListsUserActivityDataTable
                        userActivity={userActivity}
                        selectableOptions={{
                            idFieldName: 'customerId',
                            selected: selectedCustomers,
                            setSelected: setSelectedCustomers
                        }}
                    />
                </Column>
            )
        }
    ];

    const renderSearchResults = () => {
        if (!responseData.textItemsData.length || !responseData.userActivityData.length) {
            return null;
        }
        const leftColumnCards = createLeftColumnCards(responseData.textItemsData, selectedTextItems);
        const rightColumnCards = createRightColumnCards(responseData.userActivityData, selectedCustomers);
        return (
            <Row alignmentVertical='top'>
                <Column width='50%'>
                    {leftColumnCards.map((card) => (
                        <DetailsCard
                            key={card.name}
                            name={card.name}
                            CustomCardContentElement={card.CustomCardContentElement}
                        />
                    ))}
                </Column>
                <Column width='50%'>
                    {rightColumnCards.map((card) => (
                        <DetailsCard
                            key={card.name}
                            name={card.name}
                            CustomCardContentElement={card.CustomCardContentElement}
                        />
                    ))}
                </Column>
            </Row>
        );
    };

    return (
        <Container>
            {renderSearch()}
            <Divider />
            <VerticalSpacer>
                {(responseData.fetching && renderLoader()) ||
                    (responseData.error && renderError()) ||
                    (responseData.textItemsData.length &&
                        responseData.userActivityData.length &&
                        renderSearchResults()) ||
                    null}
            </VerticalSpacer>
        </Container>
    );
};

const Container = styled.div`
    padding: 15px 10px;
`;

const VerticalSpacer = styled.div`
    padding: 15px 0;
`;

const HorizontalSpacer = styled.div`
    padding: 0 15px;
`;
