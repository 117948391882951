import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import SideMenu, { SideMenuLink, SideMenuTitle } from '@amzn/meridian/side-menu';

import { LinkData, RATINGS_LINKS, REVIEWS_LINKS, SINGLE_LINKS, LISTS_LINKS } from '../AppNavigator/ProjectLinks';
import { Project, Service } from '../constants';

interface Props {
    activeService?: Service;
    activeProject: Project;
    setActiveService: (serviceId?: Service) => void;
    setActiveProject: (projectId: Project) => void;
    isSideBarOpen: boolean;
}

export const SideBar: React.FC<Props> = (props) => {
    const { activeService, setActiveProject, setActiveService, isSideBarOpen } = props;
    const [openService, setOpenService] = React.useState<Service | undefined>(activeService);

    const navigate = useNavigate();
    const location = useLocation();
    const handleLinkClick = (linkData: LinkData) => {
        navigate(linkData.link);
        setActiveService(linkData.service);
        setActiveProject(linkData.id);
    };

    const renderServiceMenuLinks = (service: Service, linksData: LinkData[]) => {
        return (
            <SideMenuLink
                key={service}
                href={service}
                open={openService === service}
                onClick={(serviceId) => {
                    setOpenService(openService === service ? null : serviceId);
                }}
            >
                {service}
                {linksData.map((linkData) => {
                    return (
                        <SideMenuLink
                            key={linkData.id}
                            selected={location.pathname === linkData.link}
                            onClick={() => handleLinkClick(linkData)}
                        >
                            {linkData.text}
                        </SideMenuLink>
                    );
                })}
            </SideMenuLink>
        );
    };

    return (
        <SideMenu open={isSideBarOpen}>
            <SideMenuTitle>Services</SideMenuTitle>
            {renderServiceMenuLinks(
                'Ratings',
                Object.keys(RATINGS_LINKS).map((key) => RATINGS_LINKS[key])
            )}
            {renderServiceMenuLinks(
                'Reviews',
                Object.keys(REVIEWS_LINKS).map((key) => REVIEWS_LINKS[key])
            )}
            {renderServiceMenuLinks(
                'Lists',
                Object.keys(LISTS_LINKS).map((key) => LISTS_LINKS[key])
            )}
            {SINGLE_LINKS.map((linkData) => {
                return (
                    <SideMenuLink
                        key={linkData.id}
                        selected={location.pathname.includes(linkData.link)}
                        onClick={() => handleLinkClick(linkData)}
                    >
                        {linkData.text}
                    </SideMenuLink>
                );
            })}
        </SideMenu>
    );
};
