import * as React from 'react';

import Card, { CardHeader } from '@amzn/meridian/card';
import Heading from '@amzn/meridian/heading';

export interface WorkInProgressPageProps {
    project: string;
}

export const WorkInProgressPage: React.FC<WorkInProgressPageProps> = (props: WorkInProgressPageProps) => {
    const { project } = props;

    return (
        <Card>
            <CardHeader>
                <Heading level={2} type='h500'>
                    This page is still in development - {project}
                </Heading>
            </CardHeader>
        </Card>
    );
};
