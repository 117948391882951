import React from 'react';
import { useLocation } from 'react-router';

import Alert from '@amzn/meridian/alert';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';

import { RequestErrorCard } from '../../../../features/error/RequestErrorCard';
import { ListSpamWorkflowDataTable } from '../../../../features/ListSpamWorkflowDataTable/ListSpamWorkflowDataTable';
import { ListSpamWorkflowErrorTable } from '../../../../features/ListSpamWorkflowDataTable/ListSpamWorkflowErrorTable';

export interface ListSpamWorkflowResponseData {
    customerId: string;
}

export interface ListSpamWorkflowError {
    id: string;
    code: string;
    message: string;
}

export const ListSpamWorkflowResults: React.VFC = () => {
    const { state } = useLocation();
    const [customerIds, setCustomerIds] = React.useState<ListSpamWorkflowResponseData[]>([]);
    const [errorIds, setErrorIds] = React.useState<ListSpamWorkflowError[]>([]);
    const [error, setError] = React.useState<any>(null);

    React.useEffect(() => {
        async function convertDataToRows() {
            try {
                if (state && state.listsOwners && state.listsOwners.customerIds.length > 0) {
                    const customerIdRows: ListSpamWorkflowResponseData[] = state.listsOwners.customerIds.map((id) => ({
                        'customerId': id
                    }));
                    setCustomerIds(customerIdRows);
                }
                if (state && state.listsOwners && state.listsOwners.errors.length > 0) {
                    setErrorIds(state.listsOwners.errors);
                }
            } catch (e: any) {
                setError(e);
            }
        }
        convertDataToRows();
    }, []);

    const showError = () => <RequestErrorCard error={error} />;

    const showTables = () => {
        return (
            <>
                {state && state.successMessage && state.listsOwners && (
                    <Alert type='success'>{state.successMessage}</Alert>
                )}
                <Heading level={1}>List Spam Workflow Search Results</Heading>
                <Row spacingInset='300' backgroundColor='secondary'></Row>
                {state &&
                    state.listsOwners &&
                    state.listsOwners.customerIds &&
                    state.listsOwners.customerIds.length > 0 && <ListSpamWorkflowDataTable responseIds={customerIds} />}
                {state && state.listsOwners && state.listsOwners.errors && state.listsOwners.errors.length > 0 && (
                    <>
                        <Heading level={2}>Errors</Heading>
                        <ListSpamWorkflowErrorTable responseErrors={errorIds} />
                    </>
                )}
            </>
        );
    };

    return <>{(error && showError()) || showTables()}</>;
};
