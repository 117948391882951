import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    Header,
    SelectableOptions
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import { SelectInput } from '@amzn/imdb-shared-meridian-components/components/InputForm/SelectInput/SelectInput';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-knockout';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';
import Pagination from '@amzn/meridian/pagination';
import Row from '@amzn/meridian/row';
import SearchField from '@amzn/meridian/search-field';
import { TableActionBarProps } from '@amzn/meridian/table-action-bar/table-action-bar';
import Text from '@amzn/meridian/text';

import { FrequentlyUsedText } from '../../../../trustAdmin-api/generated-src';

export type ColumnId = 'text' | 'occurrences';

export type SelectedTextItems = { [text: string]: boolean };

interface ListsTextItemsDataTableProps {
    textItems: FrequentlyUsedText[];
    selectableOptions?: SelectableOptions;
}

const DEFAULT_ITEMS_PER_PAGE = 15;
const ITEMS_PER_PAGE_OPTIONS = [15, 50, 100];

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    return <DefaultTableCellContent data={row[headerId]} />;
};

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const createTablesData = (textItems: FrequentlyUsedText[]): Data<ColumnId> => {
    return {
        headers: createHeaders(),
        rows: textItems
    };
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'text',
            label: 'Bigram'
        },
        {
            id: 'occurrences',
            label: 'Occurrences'
        }
    ];
};

const createActionBarProps = (
    itemsPerPage: number,
    setItemsPerPage: Dispatch<SetStateAction<number>>,
    query: string,
    setQuery: Dispatch<SetStateAction<string>>,
    searchError: string
): TableActionBarProps => {
    const options = ITEMS_PER_PAGE_OPTIONS.map((option) => ({ option, value: option, label: option }));
    return {
        children: (
            <Row alignmentVertical='bottom' spacing='500'>
                <SelectInput
                    label='Items per page'
                    id='items_per_page'
                    value={itemsPerPage}
                    onChange={setItemsPerPage}
                    options={options}
                />
                <HorizontalSpacer>
                    <SearchField value={query} onChange={setQuery} placeholder='Search...' onSubmit={setQuery} />
                    {searchError ? (
                        <HorizontalSpacer>
                            <Row spacing='200'>
                                <Icon tokens={alertKnockoutTokens} />
                                <Text type='h100'>{searchError}</Text>
                            </Row>
                        </HorizontalSpacer>
                    ) : null}
                </HorizontalSpacer>
            </Row>
        )
    };
};

export const ListsTextItemsDataTable: React.VFC<ListsTextItemsDataTableProps> = (props) => {
    const { textItems, selectableOptions } = props;

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(DEFAULT_ITEMS_PER_PAGE);
    const [query, setQuery] = useState('');

    let searchError = '';
    let filteredItems = textItems;
    if (query) {
        filteredItems = textItems.filter((ti) => ti.text.toLowerCase().startsWith(query.toLowerCase()));
        if (filteredItems.length < 1) {
            filteredItems = textItems;
            searchError = 'No text items match your search';
        } else {
            searchError = '';
        }
    }

    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex =
        filteredItems.length < itemsPerPage ? filteredItems.length : firstVisibleIndex + itemsPerPage;
    const numberOfPages = Math.ceil(filteredItems.length / itemsPerPage);
    const lastVisibleTextItem = Math.min(lastVisibleIndex, filteredItems.length);

    return (
        <Column width='100%'>
            <Heading level={5}>
                {`Showing text items ${firstVisibleIndex + 1} to ${lastVisibleTextItem} of ${
                    filteredItems.length
                } total`}
            </Heading>
            <DataTable
                data={createTablesData(filteredItems.slice(firstVisibleIndex, lastVisibleIndex))}
                actionBarProps={createActionBarProps(itemsPerPage, setItemsPerPage, query, setQuery, searchError)}
                selectableOptions={
                    selectableOptions && {
                        idFieldName: selectableOptions.idFieldName,
                        selected: selectableOptions.selected,
                        setSelected: selectableOptions.setSelected
                    }
                }
                CustomRowTableCell={CustomRowTableCell}
            />
            <Pagination
                showSkipArrows={true}
                numberOfPages={numberOfPages}
                onChange={setCurrentPage}
                currentPage={currentPage}
            />
        </Column>
    );
};

const HorizontalSpacer = styled.div`
    padding: 0 10px;
    display: inherit;
`;
