import axios from 'axios';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
// Promise polyfill
import 'core-js/features/promise';

import { App } from './app/App';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import { initializeAppSettings } from './config/AppSettings';

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
    const jwtToken = await getMidwayJwtToken();
    config.headers.Authorization = `Bearer ${jwtToken}`;
    return config;
});

(async () => {
    // Make sure Midway is present before rendering
    await getMidwayJwtToken();

    // Initialize application settings
    const appSettings = (await axios('/settings.json')).data;
    initializeAppSettings(appSettings);

    const container = document.getElementById('app');
    const root = createRoot(container!);
    root.render(<App />);
})();
