import { getAppSetting } from '../config/AppSettings';
import {
    SuspiciousTitlesOutputApi,
    SuspiciousTitlesExportDataApi,
    TitleDetailsApi,
    TitleRatingsExportRequestApi,
    TitleRatingsExportStatusApi,
    BulkUpdateUserStatusApi,
    BulkUpdateUserStatusStatusApi,
    GetListsOwnersApi,
    GetListsTextSearchDetailsApi
} from './generated-src';

export default function () {
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
    return {
        suspiciousTitlesOutput: new SuspiciousTitlesOutputApi(undefined, apiBasePath),
        suspiciousTitlesExportData: new SuspiciousTitlesExportDataApi(undefined, apiBasePath),
        titleDetails: new TitleDetailsApi(undefined, apiBasePath),
        titleRatingsExportRequest: new TitleRatingsExportRequestApi(undefined, apiBasePath),
        titleRatingsExportStatus: new TitleRatingsExportStatusApi(undefined, apiBasePath),
        listsOwners: new GetListsOwnersApi(undefined, apiBasePath),
        listsTextSearchDetails: new GetListsTextSearchDetailsApi(undefined, apiBasePath),
        bulkUpdateUserStatus: new BulkUpdateUserStatusApi(undefined, apiBasePath),
        bulkUpdateUserStatusStatus: new BulkUpdateUserStatusStatusApi(undefined, apiBasePath)
    };
}
