import React, { useState, Dispatch, SetStateAction } from 'react';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    Header,
    SelectableOptions
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import { SelectInput } from '@amzn/imdb-shared-meridian-components/components/InputForm/SelectInput/SelectInput';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Pagination from '@amzn/meridian/pagination';
import { TableActionBarProps } from '@amzn/meridian/table-action-bar/table-action-bar';

import { UserActivityItem } from '../../../../trustAdmin-api/generated-src';

export type ColumnId = 'customerId' | 'modifiedListCount';

export type SelectedCustomers = { [customerId: string]: boolean };

interface ListsUserActivityDataTableProps {
    userActivity: UserActivityItem[];
    selectableOptions?: SelectableOptions;
}

const DEFAULT_ITEMS_PER_PAGE = 15;
const ITEMS_PER_PAGE_OPTIONS = [15, 50, 100];

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    return <DefaultTableCellContent data={row[headerId]} />;
};

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const createTablesData = (userActivity: UserActivityItem[]): Data<ColumnId> => {
    return {
        headers: createHeaders(),
        rows: userActivity
    };
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'customerId',
            label: 'Customer Id'
        },
        {
            id: 'modifiedListCount',
            label: 'Modified lists'
        }
    ];
};

const createActionBarProps = (
    itemsPerPage: number,
    setItemsPerPage: Dispatch<SetStateAction<number>>
): TableActionBarProps => {
    const options = ITEMS_PER_PAGE_OPTIONS.map((option) => ({ option, value: option, label: option }));
    return {
        children: (
            <SelectInput
                label='Items per page'
                id='items_per_page'
                value={itemsPerPage}
                onChange={setItemsPerPage}
                options={options}
            />
        )
    };
};

export const ListsUserActivityDataTable: React.VFC<ListsUserActivityDataTableProps> = (props) => {
    const { userActivity, selectableOptions } = props;

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(DEFAULT_ITEMS_PER_PAGE);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex =
        userActivity.length < itemsPerPage ? userActivity.length : firstVisibleIndex + itemsPerPage;
    const numberOfPages = Math.ceil(userActivity.length / itemsPerPage);
    const lastVisibleUser = Math.min(lastVisibleIndex, userActivity.length);

    return (
        <Column width='100%'>
            <Heading level={5}>
                {`Showing users ${firstVisibleIndex + 1} to ${lastVisibleUser} of ${userActivity.length} total`}
            </Heading>
            <DataTable
                data={createTablesData(userActivity.slice(firstVisibleIndex, lastVisibleIndex))}
                actionBarProps={createActionBarProps(itemsPerPage, setItemsPerPage)}
                selectableOptions={
                    selectableOptions && {
                        idFieldName: selectableOptions.idFieldName,
                        selected: selectableOptions.selected,
                        setSelected: selectableOptions.setSelected
                    }
                }
                CustomRowTableCell={CustomRowTableCell}
            />
            <Pagination
                showSkipArrows={true}
                numberOfPages={numberOfPages}
                onChange={setCurrentPage}
                currentPage={currentPage}
            />
        </Column>
    );
};
