import * as React from 'react';

import {
    DetailsTableCellData,
    DetailsTableCell
} from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableCell';
import {
    CellHeaders,
    CustomDetailsTableRow
} from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableRow';
import Table, { TableSectionHeader } from '@amzn/meridian/table';

import {
    TitleDetailsResponseRatingsDetails,
    TitleDetailsResponseRatingsDetailsRevisedRatings
} from '../../../../../trustAdmin-api/generated-src';
import { MISSING_VALUE } from '../../consts';

type LastAppliedCellId = 'rrc' | 'date_applied' | 'approver';

const LAST_APPLIED_CELL_HEADERS: CellHeaders<LastAppliedCellId> = {
    rrc: {
        id: 'rrc',
        label: 'RRC'
    },
    date_applied: {
        id: 'date_applied',
        label: 'Date applied'
    },
    approver: {
        id: 'approver',
        label: 'Approved by'
    }
};

type RatingDetailsTableProps = Pick<TitleDetailsResponseRatingsDetails, 'revisedRatings'>;

const TABLE_HEADER = 'RRC details';
const LAST_APPLIED_ROW_HEADER = 'Last applied';

export const RrcDetailsTable: React.FC<RatingDetailsTableProps> = (props) => {
    const { revisedRatings } = props;
    if (!revisedRatings) {
        return null;
    }
    return (
        <Table
            data-test-id='rrcDetailsTable'
            showDividers={true}
            spacing='small'
            rowComponents={[CustomDetailsTableRow]}
        >
            <TableSectionHeader>{TABLE_HEADER}</TableSectionHeader>
            <CustomDetailsTableRow
                header={LAST_APPLIED_ROW_HEADER}
                cellHeaders={LAST_APPLIED_CELL_HEADERS}
                data={revisedRatings}
                getTableCell={getRrcLastAppliedTableCell}
            />
        </Table>
    );
};

const getRrcLastAppliedTableCell = (
    cellId: LastAppliedCellId,
    revisedRatings: TitleDetailsResponseRatingsDetailsRevisedRatings
) => {
    const tableCell = (data: DetailsTableCellData | null) => {
        const detailsTableCellData = data !== null ? data : MISSING_VALUE;
        return (
            <DetailsTableCell
                key={cellId}
                data={detailsTableCellData}
                subHeader={LAST_APPLIED_CELL_HEADERS[cellId].label}
                width='25%'
            />
        );
    };
    switch (cellId) {
        case 'rrc': {
            return tableCell(revisedRatings.rating);
        }
        case 'date_applied': {
            return tableCell(revisedRatings.date);
        }
        case 'approver': {
            return tableCell(revisedRatings.approver);
        }
    }
};
