import React from 'react';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    Header
} from '@amzn/imdb-shared-meridian-components/components/DataTable';

import { ListSpamWorkflowError } from '../../pages/lists/ManualEntry/ListSpamWorkflowResults/ListSpamWorkflowResults';

export type ColumnId = 'id' | 'code' | 'message';

interface ListSpamWorkflowDataTableProps {
    responseErrors: ListSpamWorkflowError[];
}

export const ListSpamWorkflowErrorTable: React.VFC<ListSpamWorkflowDataTableProps> = (props) => {
    const { responseErrors } = props;

    return <DataTable data={createTablesData(responseErrors)} CustomRowTableCell={CustomRowTableCell} />;
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    return <DefaultTableCellContent data={row[headerId]} />;
};

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const createTablesData = (customerIds: ListSpamWorkflowError[]): Data<ColumnId> => {
    return {
        headers: createHeaders(),
        rows: customerIds
    };
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'id',
            label: 'Input Id'
        },
        {
            id: 'code',
            label: 'Error Code'
        },
        {
            id: 'message',
            label: 'Error Message'
        }
    ];
};
