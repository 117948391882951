import orderBy from 'lodash/orderBy';
import React from 'react';
import styled from 'styled-components';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    Header,
    OnSortArgs,
    Sort
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import { TableSortDirection } from '@amzn/meridian/table/table';

import { JobStatus, UserStatusUpdaterStatus } from '../../../trustAdmin-api/generated-src';
import TrustAdminApiFactory from '../../../trustAdmin-api/TrustAdminApiFactory';
import { translateToLodashSortDirection } from '../../../utils/sort';
import { RequestErrorCard } from '../error/RequestErrorCard';

export type ColumnId =
    | 'status'
    | 'created'
    | 'requestId'
    | 'context'
    | 'feature'
    | 'requestingUser'
    | 'bucketName'
    | 'successes'
    | 'overrides'
    | 'faults';

export const BulkShadowbanStatusDataTable: React.FC = () => {
    const [statuses, setStatuses] = React.useState<UserStatusUpdaterStatus[]>([]);
    const [sortColumn, setSortColumn] = React.useState<ColumnId>();
    const [sortDirection, setSortDirection] = React.useState<TableSortDirection>();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<any>(null);

    const onSort = (args: OnSortArgs<ColumnId>) => {
        setSortColumn(args.sortColumn);
        setSortDirection(args.sortDirection);
        setStatuses(orderBy(statuses, [args.sortColumn], [translateToLodashSortDirection(args.sortDirection)]));
    };

    const sort: Sort<ColumnId> = {
        column: sortColumn,
        direction: sortDirection,
        onSort: onSort
    };

    React.useEffect(() => {
        async function fetchBulkShadowbanStatuses() {
            try {
                const response = await TrustAdminApiFactory().bulkUpdateUserStatusStatus.bulkUpdateUserStatusStatus();
                setStatuses(orderBy(response.data.statuses, ['created'], 'desc'));
            } catch (e: any) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
        fetchBulkShadowbanStatuses();
    }, []);

    const showError = () => <RequestErrorCard error={error} />;

    const showTable = () => (
        <DataTable
            data={createTablesData(statuses)}
            isLoading={isLoading}
            sort={sort}
            CustomRowTableCell={CustomRowTableCell}
        />
    );

    return (error && showError()) || showTable();
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'status':
            return <StatusColorTableCellContent data={row[headerId]} />;
        case 'created':
        case 'requestId':
            return <DefaultTableCellContent data={row[headerId]} />;
        default:
            return <DefaultTableCellContent data={row['metadata'][headerId]} />;
    }
};

const StatusColorTableCellContent = ({ data }) => <StatusText data={data}>{data}</StatusText>;

const getStatusColor = (status) => {
    switch (status) {
        case JobStatus.Succeeded:
            return 'green';
        case JobStatus.Failed:
            return 'red';
        case JobStatus.Running:
            return 'blue';
        default:
            return 'black';
    }
};

const StatusText = styled.span`
    color: ${(props: { data: string }) => getStatusColor(props.data)};
`;

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const createTablesData = (statuses: UserStatusUpdaterStatus[]): Data<ColumnId> => {
    return {
        headers: createHeaders(),
        rows: statuses
    };
};

const createHeaders = (): Header<ColumnId>[] => {
    return [
        {
            id: 'status',
            label: 'Status'
        },
        {
            id: 'created',
            label: 'Created'
        },
        {
            id: 'requestId',
            label: 'Request ID'
        },
        {
            id: 'context',
            label: 'Context'
        },
        {
            id: 'feature',
            label: 'Feature'
        },
        {
            id: 'requestingUser',
            label: 'Requested By'
        },
        {
            id: 'bucketName',
            label: 'Bucket Name'
        },
        {
            id: 'successes',
            label: 'Successes'
        },
        {
            id: 'overrides',
            label: 'Overrides'
        },
        {
            id: 'faults',
            label: 'Faults'
        }
    ];
};
