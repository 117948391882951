import React from 'react';
import styled from 'styled-components';

import {
    AlertMessage,
    InputFieldProps,
    InputForm,
    INPUT_TYPE
} from '@amzn/imdb-shared-meridian-components/components/InputForm';
import { InputModal } from '@amzn/imdb-shared-meridian-components/components/InputModal';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import { ModalProps } from '@amzn/meridian/modal/modal';
import Text from '@amzn/meridian/text';

import { ListSpamWorkflowFormInput, ListSpamWorkflowFormResponseData } from './ListSpamWorkflowForm';

interface ListSpamWorkflowFormViewProps {
    inputData: ListSpamWorkflowFormInput;
    onInputChange: (_: object) => void;
    responseData: ListSpamWorkflowFormResponseData;
    isModalOpen: boolean;
    setIsModalOpen: (_: boolean) => void;
    onSubmit: () => void;
    validateInput: () => void;
}

export const ListSpamWorkflowFormView: React.VFC<ListSpamWorkflowFormViewProps> = (props) => {
    const { inputData, onInputChange, responseData, isModalOpen, setIsModalOpen, onSubmit, validateInput } = props;

    const showIdsInput = (): string => inputData?.ids?.join('\n') || '';

    const setIds = (ids: string) => {
        onInputChange({ ids: ids.split('\n') });
    };

    const createInputFields = (): InputFieldProps[] => [
        {
            type: INPUT_TYPE.TEXTAREA,
            id: 'ids',
            value: showIdsInput(),
            onChange: setIds,
            textLabel: 'Find Suspicious Users',
            rows: 10,
            descriptionLabel: 'Enter one or more Customer (e.g. AUYM123456789) or List IDs (e.g. ls1234567890).',
            helperText: 'Enter ID values one per each line. Do not use any other delimiters such as "," or "|"'
        }
    ];

    const showLoader = () => {
        return (
            <Column width='50%' alignmentHorizontal='center'>
                <Loader />
                <Text>Finding suspicious users...</Text>
            </Column>
        );
    };

    const showSpamWorkflowForm = () => {
        return (
            <Column width='50%'>
                <InputModal setIsOpen={setIsModalOpen} modalProps={createModalProps(isModalOpen)} onSubmit={onSubmit} />
                <InputForm
                    inputs={createInputFields()}
                    onSubmit={validateInput}
                    alertMessage={getAlertMessage(responseData)}
                />
            </Column>
        );
    };

    return <Container>{(responseData.fetching && showLoader()) || showSpamWorkflowForm()}</Container>;
};

const createModalProps = (isOpen: boolean): ModalProps => {
    return {
        title: 'List Spam Workflow',
        open: isOpen,
        children: [<Text key='description'>{`Search for these suspicious users or lists?`}</Text>]
    };
};

const getAlertMessage = (responseData: ListSpamWorkflowFormResponseData): AlertMessage | undefined => {
    if (responseData.error) {
        return {
            type: 'error',
            message: responseData.error.message
        };
    }
    return undefined;
};

const Container = styled.div`
    padding: 15px 10px;
`;
