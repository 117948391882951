import { Project, RatingsProjects, ReviewsProjects, ListsProjects, Service } from '../constants';

type RatingsProjectsLinks = { [key in RatingsProjects]: LinkData };
type ReviewsProjectsLinks = { [key in ReviewsProjects]: LinkData };
type ListsProjectsLinks = { [key in ListsProjects]: LinkData };

export interface LinkData {
    text: string;
    id: Project;
    link: string;
    service?: Service;
}

const RATINGS_HOME: LinkData = {
    text: 'Home',
    id: 'Home',
    link: '/ratings',
    service: 'Ratings'
};

const RATINGS_RRC: LinkData = {
    text: 'RRC',
    id: 'RRC',
    link: '/ratings/rrc',
    service: 'Ratings'
};

const RATINGS_SUSPICIOUS_TITLES: LinkData = {
    text: 'Suspicious Titles',
    id: 'SuspiciousTitles',
    link: '/ratings/suspiciousTitles',
    service: 'Ratings'
};

const REVIEWS_HOME: LinkData = {
    text: 'Home',
    id: 'Home',
    link: '/reviews',
    service: 'Reviews'
};

const REVIEWS_SPAM: LinkData = {
    text: 'Spam',
    id: 'Spam',
    link: '/reviews/spam',
    service: 'Reviews'
};

export const TITLE_SEARCH: LinkData = {
    text: 'Title Search',
    id: 'TitleSearch',
    link: '/titleSearch'
};

const BULK_SHADOWBAN: LinkData = {
    text: 'Bulk Shadowban',
    id: 'BulkShadowban',
    link: '/bulkShadowban'
};

const LISTS_MANUAL_ENTRY: LinkData = {
    text: 'Manual Entry',
    id: 'ManualEntry',
    link: '/lists/manualEntry'
};

const LISTS_TEXT_SEARCH: LinkData = {
    text: 'Text Search',
    id: 'TextSearch',
    link: '/lists/textSearch'
};

export const RATINGS_LINKS: RatingsProjectsLinks = {
    Home: RATINGS_HOME,
    RRC: RATINGS_RRC,
    SuspiciousTitles: RATINGS_SUSPICIOUS_TITLES
};

export const REVIEWS_LINKS: ReviewsProjectsLinks = {
    Home: REVIEWS_HOME,
    Spam: REVIEWS_SPAM
};

export const LISTS_LINKS: ListsProjectsLinks = {
    ManualEntry: LISTS_MANUAL_ENTRY,
    TextSearch: LISTS_TEXT_SEARCH
};

export const SINGLE_LINKS: LinkData[] = [TITLE_SEARCH, BULK_SHADOWBAN];

export const PROJECT_LINKS: LinkData[] = [
    ...Object.keys(RATINGS_LINKS).map((key) => RATINGS_LINKS[key]),
    ...Object.keys(REVIEWS_LINKS).map((key) => REVIEWS_LINKS[key]),
    ...Object.keys(LISTS_LINKS).map((key) => LISTS_LINKS[key]),
    ...SINGLE_LINKS
];
