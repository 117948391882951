import React from 'react';
import { Outlet, Location, useLocation, useNavigate } from 'react-router-dom';

import { PageSection } from '@amzn/imdb-shared-meridian-components/components/PageSection';
import { ResetCssStyles } from '@amzn/imdb-shared-meridian-components/components/ResetCssStyles';
import AppLayout from '@amzn/meridian/app-layout';
import Theme from '@amzn/meridian/theme';

import { LinkData, PROJECT_LINKS, RATINGS_LINKS } from './AppNavigator/ProjectLinks';
import { Project, Service } from './constants';
import { Header } from './Header/Header';
import { SideBar } from './SideBar/SideBar';

export const Layout: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const currentLink = geCurrentLinkData(location);

    const [activeService, setActiveService] = React.useState<Service | undefined>(currentLink.service);
    const [activeProject, setActiveProject] = React.useState<Project>(currentLink.id);
    const [isSideBarOpen, setIsSideBarOpen] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (location.pathname === '/') {
            navigate('/ratings');
        }
    }, []);

    return (
        <Theme palette='blue' mode='light'>
            <>
                <ResetCssStyles />
                <AppLayout headerComponent={Header} sidebarComponent={SideBar}>
                    <Header isSideBarOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
                    <SideBar
                        isSideBarOpen={isSideBarOpen}
                        activeService={activeService}
                        activeProject={activeProject}
                        setActiveService={setActiveService}
                        setActiveProject={setActiveProject}
                    />
                    <PageSection>
                        <Outlet />
                    </PageSection>
                </AppLayout>
            </>
        </Theme>
    );
};

const geCurrentLinkData = (location: Location): LinkData => {
    const currentLinks = PROJECT_LINKS.filter((linkData) => location.pathname === linkData.link);
    if (currentLinks.length === 0) {
        return RATINGS_LINKS.Home;
    }
    return currentLinks[0];
};
