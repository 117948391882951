import { AxiosError } from 'axios';
import React from 'react';
import styled from 'styled-components';

import {
    AlertMessage,
    InputFieldProps,
    InputForm,
    INPUT_TYPE
} from '@amzn/imdb-shared-meridian-components/components/InputForm';
import { InputModal } from '@amzn/imdb-shared-meridian-components/components/InputModal';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import { ModalProps } from '@amzn/meridian/modal/modal';
import { SelectOptionProps } from '@amzn/meridian/select/select-option';
import Text from '@amzn/meridian/text';

import { FeatureType } from '../../../trustAdmin-api/generated-src';

interface BulkUpdateUserStatusFormInput {
    customerIds?: string[];
    shadowbanContext?: string;
    feature?: FeatureType;
}

interface BulkUpdateUserStatusResponseData {
    trustedCustomerIds?: string[];
    fetching?: boolean;
    error?: AxiosError;
}

interface BulkShadowbanFormViewProps {
    inputData: BulkUpdateUserStatusFormInput;
    onInputChange: (_: object) => void;
    responseData: BulkUpdateUserStatusResponseData;
    isModalOpen: boolean;
    setIsModalOpen: (_: boolean) => void;
    onSubmit: () => void;
    validateInput: () => void;
}

export const BulkShadowbanFormView: React.VFC<BulkShadowbanFormViewProps> = (props) => {
    const { inputData, onInputChange, responseData, isModalOpen, setIsModalOpen, onSubmit, validateInput } = props;

    const showCustomerIdsInput = (): string => inputData?.customerIds?.join('\n') || '';

    const getFeatureSelectOptions = (): SelectOptionProps[] => {
        return Object.keys(FeatureType).map((feature) => {
            return { key: FeatureType[feature], value: feature, label: feature };
        });
    };

    const setFeature = (feature: FeatureType) => {
        onInputChange({ feature });
    };

    const setShadowbanContext = (shadowbanContext: string) => {
        onInputChange({ shadowbanContext });
    };

    const setCustomerIds = (customerIdsString: string) => {
        onInputChange({ customerIds: customerIdsString.split('\n') });
    };

    const createInputFields = (): InputFieldProps[] => [
        {
            type: INPUT_TYPE.SELECT,
            id: 'featureTypeDropdown',
            value: inputData?.feature || '',
            onChange: setFeature,
            textLabel: 'Feature',
            options: getFeatureSelectOptions(),
            placeholder: 'Feature'
        },
        {
            type: INPUT_TYPE.TEXT,
            id: 'context',
            value: inputData?.shadowbanContext || '',
            onChange: setShadowbanContext,
            textLabel: 'Shadowban Context',
            descriptionLabel:
                'Do not put any personal customer information or anything that can be used to identify customers such as email. Do not include any "," or "|" in the context.',
            label: 'Enter context...'
        },
        {
            type: INPUT_TYPE.TEXTAREA,
            id: 'customerIds',
            value: showCustomerIdsInput(),
            onChange: setCustomerIds,
            textLabel: 'Customer IDs',
            rows: 20,
            helperText: 'Enter Customer ID values one per each line. Do not use any other delimiters such as "," or "|"'
        }
    ];

    const showLoader = () => {
        return (
            <Column width='50%' alignmentHorizontal='center'>
                <Loader />
                <Text>Updating user statuses...</Text>
            </Column>
        );
    };

    const showBulkShadowbanForm = () => {
        return (
            <Column width='50%'>
                <InputModal
                    setIsOpen={setIsModalOpen}
                    modalProps={createModalProps(isModalOpen, inputData?.feature)}
                    onSubmit={onSubmit}
                />
                <InputForm
                    inputs={createInputFields()}
                    onSubmit={validateInput}
                    alertMessage={getAlertMessage(responseData, inputData?.feature)}
                />
            </Column>
        );
    };

    return <Container>{(responseData.fetching && showLoader()) || showBulkShadowbanForm()}</Container>;
};

const createModalProps = (isOpen: boolean, feature?: FeatureType): ModalProps => {
    return {
        title: 'Bulk Shadowban',
        open: isOpen,
        children: [
            <Text key='description'>{`Are you sure you want to bulk shadowban these accounts${
                feature ? ` for ${feature}` : ''
            }?`}</Text>
        ]
    };
};

const getAlertMessage = (
    responseData: BulkUpdateUserStatusResponseData,
    feature?: FeatureType
): AlertMessage | undefined => {
    if (responseData.error) {
        return {
            type: 'error',
            message: responseData.error.message
        };
    }
    if (FeatureType[feature!] == FeatureType.UserLists) {
        return {
            type: 'warning',
            message:
                'Per DSA requirement, shadowbanning user lists must only apply to cases of "...intentional manipulation of the service, in particular inauthentic use of the service such as the use of bots or fake accounts or other deceptive uses of the service"'
        };
    }
    return undefined;
};

const Container = styled.div`
    padding: 15px 10px;
`;
