import React from 'react';

import { TitleDetailsSection } from '../../../features/TitleDetailsSection/TitleDetailsSection';
import { useTitleSearchContext } from '../TitleSearchLayout';

export const TitleSearch: React.VFC = () => {
    const { tconstInput, warning, titleDetailsQuery } = useTitleSearchContext();

    return (
        <>
            {tconstInput && !warning && (
                <TitleDetailsSection searchValue={tconstInput} titleDetailsQuery={titleDetailsQuery} />
            )}
        </>
    );
};
