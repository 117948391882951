import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Heading from '@amzn/meridian/heading';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';

import { BulkShadowbanStatusDataTable } from '../../../features/BulkShadowbanStatusDataTable/BulkShadowbanStatusDataTable';

export const BulkShadowbanStatus: React.VFC = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    return (
        <>
            {state && state.successMessage && <Alert type='success'>{state.successMessage}</Alert>}
            <Heading level={1}>Bulk Shadowban Status</Heading>
            <Heading level={5}>
                Progress can also be tracked here:{' '}
                <Link href='https://w.amazon.com/bin/view/IMDb/Ratings/Duckula/V2Dashboard/' target='_blank'>
                    https://w.amazon.com/bin/view/IMDb/Ratings/Duckula/V2Dashboard/
                </Link>
            </Heading>
            <Row spacingInset='300' backgroundColor='secondary'>
                <Button href='/bulkShadowban/create/' onClick={navigate}>
                    Create New Request
                </Button>
            </Row>
            <BulkShadowbanStatusDataTable />
        </>
    );
};
