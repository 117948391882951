import React from 'react';

import Heading from '@amzn/meridian/heading';

import { ListsTextSearchForm } from '../../../../features/ListsTextSearchForm/ListsTextSearchForm';

export const ListsTextSearch: React.VFC = () => {
    return (
        <>
            <Heading level={1}>Find frequently used text on lists</Heading>
            <ListsTextSearchForm />
        </>
    );
};
