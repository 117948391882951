import * as React from 'react';

import Alert from '@amzn/meridian/alert';
import Card, { CardHeader } from '@amzn/meridian/card';

export interface GenericErrorCardProps {
    message: string;
    title?: string;
}

export const GenericErrorCard: React.FC<GenericErrorCardProps> = (props: GenericErrorCardProps) => {
    const { message, title } = props;

    return (
        <Card>
            <CardHeader>
                <Alert type='error' title={title ? title : 'Error'} size='xlarge'>
                    {message}
                </Alert>
            </CardHeader>
        </Card>
    );
};
