import React from 'react';
import { createClient, Provider } from 'urql';

import { AppNavigator } from './components/AppNavigator/AppNavigator';

const graphqlClient = createClient({
    url: 'https://api.graphql.imdb.com/'
});

export const App = () => (
    <Provider value={graphqlClient}>
        <AppNavigator />
    </Provider>
);
