import * as React from 'react';

import Alert from '@amzn/meridian/alert';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Tile from '@amzn/meridian/tile';

import { TitleDetailsResponseRatingsDetails } from '../../../../../trustAdmin-api/generated-src';
import { GetTitleDetailsQuery } from '../../../../../types/graphql/GeneratedTypes';
import { MISSING_VALUE } from '../../consts';

type TileName = 'Title Rating' | 'Total Ratings' | 'Shadowbanned Accounts' | 'RRC';

type RatingsDetails = Pick<
    TitleDetailsResponseRatingsDetails,
    'shadowbannedRatingsCount' | 'revisedRatings' | 'ratingsBuildValue'
>;

export interface TitleRatingsSummaryProps {
    title: GetTitleDetailsQuery['title'];
    ratingsDetails: RatingsDetails;
}

export const TitleRatingsSummary: React.FC<TitleRatingsSummaryProps> = (props: TitleRatingsSummaryProps) => {
    const { title, ratingsDetails } = props;

    const tiles = createTiles(title, ratingsDetails);
    const rrcApplied = ratingsDetails.revisedRatings?.rating;
    return (
        <Row spacingInset='large small' widths={['grid-3', 'grid-3', 'grid-3', 'grid-3']}>
            {tiles
                .filter((tile) => tile.data != 'NoRRC')
                .map((tile) => (
                    <Tile key={tile.name} spacingInset='450'>
                        <Heading level={2} type='h200' color='secondary'>
                            {tile.name}
                        </Heading>
                        <Heading level={1}>{tile.data}</Heading>
                        {tile.name == 'Title Rating' && rrcApplied != undefined && (
                            <Alert type={'error'} size={'small'}>
                                Replaced by RRC
                            </Alert>
                        )}
                    </Tile>
                ))}
        </Row>
    );
};

interface CustomTileProps<T extends string> {
    name: T;
    data: string | number;
}

const createTiles = (
    title: GetTitleDetailsQuery['title'],
    ratingsDetails: RatingsDetails
): CustomTileProps<TileName>[] => {
    const ratingsSummary = title?.ratingsSummary;
    const tiles: CustomTileProps<TileName>[] = [
        {
            name: 'Title Rating',
            data: ratingsDetails.ratingsBuildValue ? ratingsDetails.ratingsBuildValue.toLocaleString() : MISSING_VALUE
        },
        {
            name: 'RRC',
            data: ratingsDetails.revisedRatings?.rating
                ? ratingsDetails.revisedRatings.rating.toLocaleString()
                : 'NoRRC'
        },
        {
            name: 'Total Ratings',
            data: ratingsSummary?.voteCount ? ratingsSummary?.voteCount.toLocaleString() : MISSING_VALUE
        },
        {
            name: 'Shadowbanned Accounts',
            data: ratingsDetails.shadowbannedRatingsCount
                ? ratingsDetails.shadowbannedRatingsCount.toLocaleString()
                : MISSING_VALUE
        }
    ];
    return tiles;
};
