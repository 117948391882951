import * as React from 'react';

import { LoaderButton } from '@amzn/imdb-shared-meridian-components/components/LoaderButton';
import downloadSmallTokens from '@amzn/meridian-tokens/base/icon/download-small';

interface Props {
    loading: boolean;
    titleCount: string;
    lastBuildDateTime: string;
    fetch: () => Promise<any>;
    errorMessage?: string;
}

export const ActionBar: React.FC<Props> = (props) => {
    const { loading, titleCount, lastBuildDateTime, fetch, errorMessage } = props;

    return (
        <div id='outerDiv' style={{ display: 'flex' }}>
            <div>
                <LoaderButton
                    id='downloadButton'
                    text='Download CSV'
                    loading={loading}
                    onClick={fetch}
                    iconProps={{ tokens: downloadSmallTokens }}
                    errorMessage={errorMessage}
                />
            </div>
            <div style={{ position: 'absolute', right: 30 }}>
                <p id='titleCount' style={{ paddingBottom: 0, marginBottom: 0, marginTop: -5 }}>
                    {titleCount}
                </p>
                <p id='lastBuildDateTime' style={{ paddingTop: 0, marginTop: 0 }}>
                    {lastBuildDateTime}
                </p>
            </div>
        </div>
    );
};
