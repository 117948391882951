import { ChartSummary, SupportedChartType } from '../../../../trustAdmin-api/generated-src/api';
import { GetTitleDetailsQuery } from '../../../../types/graphql/GeneratedTypes';
import { ChartMetadata } from '../components/TitleDetailsTable/TitleDetailsTable';
import { CHART_INFORMATION } from '../consts';

export const getDateString = (title: GetTitleDetailsQuery['title']) => {
    const releaseDate = title?.releaseDate;
    if (releaseDate?.year && releaseDate?.month && releaseDate?.day) {
        const date = new Date(releaseDate?.year, releaseDate?.month - 1, releaseDate?.day);
        return date.toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' });
    } else {
        return 'Not available';
    }
};

export const getCountriesOfOrigin = (title: GetTitleDetailsQuery['title']) => {
    const countries = title?.countriesOfOrigin?.countries;
    if (countries) {
        return countries.map((country) => country.text).join(', ');
    } else {
        return 'Not available';
    }
};

export const getEpisodeString = (title: GetTitleDetailsQuery['title']) => {
    const episodeNumber = title?.series?.episodeNumber;
    return `S${episodeNumber?.seasonNumber}.E${episodeNumber?.episodeNumber}`;
};

export const isValidChart = (chartSummary: ChartSummary): boolean => {
    return (
        chartSummary.chartName in SupportedChartType &&
        chartSummary.position <= CHART_INFORMATION[chartSummary.chartName].maxRank
    );
};

export const getChartMetadata = (chartSummary: ChartSummary): ChartMetadata => {
    return CHART_INFORMATION[chartSummary.chartName];
};
