import gql from 'graphql-tag';

const getTitleDetailsQuery = gql`
    query GetTitleDetails($tconst: ID!) {
        title(id: $tconst) {
            id
            titleType {
                text
            }
            series {
                series {
                    id
                    titleText {
                        text
                    }
                }
                episodeNumber {
                    seasonNumber
                    episodeNumber
                }
            }
            releaseDate {
                year
                month
                day
            }
            countriesOfOrigin {
                countries {
                    text
                }
            }
            titleText {
                text
            }
            ratingsSummary {
                aggregateRating
                voteCount
            }
        }
    }
`;

export default getTitleDetailsQuery;
