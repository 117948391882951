import * as React from 'react';

import Masthead, { MastheadTitle, MastheadMenuButton, MastheadLink } from '@amzn/meridian/masthead';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';

import logo from '../../../assets/logo.svg';
import { getAppSetting } from '../../../config/AppSettings';

interface Props {
    isSideBarOpen: boolean;
    setIsSideBarOpen: (isOpen: boolean) => void;
}

export const Header: React.FC<Props> = (props) => {
    const { isSideBarOpen, setIsSideBarOpen } = props;

    const stages = ['Beta', 'Prod'];
    const currentStage = getAppSetting('stage');
    const serviceTitle = 'IMDbTrustAdmin';

    const handleMenuButtonClick = () => {
        setIsSideBarOpen(!isSideBarOpen);
    };

    const handleStageSelect = (newStage: string) => {
        if (newStage !== currentStage) {
            location.href = `https://${newStage}.trust-admin.imdb.amazon.dev`;
        }
    };

    const StageSelector = (
        <Select id='headerStageSelector' placeholder='Alpha' value={currentStage} onChange={handleStageSelect}>
            {stages.map((stage) => (
                <SelectOption
                    key={`${stage}SelectOption`}
                    data-test-id={`${stage}SelectOption`}
                    value={stage}
                    label={stage}
                    aria-disabled={stage === currentStage}
                />
            ))}
        </Select>
    );

    return (
        <Masthead>
            <MastheadMenuButton onClick={handleMenuButtonClick} />
            <MastheadTitle href='/'>
                <Row alignmentHorizontal='center' alignmentVertical='center' spacing='400'>
                    <img
                        src={logo}
                        alt='TrustAdmin Home'
                        style={{
                            display: 'block',
                            height: 28
                        }}
                    />
                    <Text> {serviceTitle} </Text>
                </Row>
            </MastheadTitle>
            <MastheadLink>{StageSelector && StageSelector}</MastheadLink>
        </Masthead>
    );
};
