import React from 'react';
import { useNavigate } from 'react-router';

import arrowLeftLargeTokens from '@amzn/meridian-tokens/base/icon/arrow-left-large';
import Button from '@amzn/meridian/button';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';

import { BulkShadowbanForm } from '../../../features/BulkShadowbanForm/BulkShadowbanForm';

export const BulkShadowbanCreate: React.VFC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Button href='/bulkShadowban' onClick={navigate}>
                <Icon tokens={arrowLeftLargeTokens} />
            </Button>
            <Heading level={1}>Create Bulk Shadowban Request</Heading>
            <BulkShadowbanForm />
        </>
    );
};
