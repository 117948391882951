import * as React from 'react';

import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';

import { GetTitleDetailsQuery } from '../../../../../types/graphql/GeneratedTypes';

export interface TitleTextProps {
    title: GetTitleDetailsQuery['title'];
    setSearchValue: (value: string) => void;
}

export const TitleText: React.FC<TitleTextProps> = (props: TitleTextProps) => {
    const { title, setSearchValue } = props;

    return (
        <Row spacingInset='small'>
            <Column>
                <Heading id='titleText' level={1}>
                    {title!.titleText?.text}
                </Heading>
                {title!.series && (
                    <Heading id='seriesText' level={2} type='h200'>
                        <Link type='secondary' onClick={() => setSearchValue(title!.series!.series.id)}>
                            {title!.series.series.titleText?.text}
                        </Link>
                    </Heading>
                )}
            </Column>
        </Row>
    );
};
