import { AxiosError } from 'axios';
import format from 'date-fns/format';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import {
    FrequentlyUsedText,
    ListsTextSearchDetailsRequest,
    UserActivityItem
} from '../../../trustAdmin-api/generated-src';
import TrustAdminApiFactory from '../../../trustAdmin-api/TrustAdminApiFactory';
import { addDays } from '../../../utils/date';
import { SelectedTextItems } from './components/ListsTextItemsDataTable';
import { SelectedCustomers } from './components/ListsUserActivityDataTable';
import { ListsTextSearchFormView } from './ListsTextSearchFormView';

export interface ListsTextSearchFormInput {
    startDate?: string;
    endDate?: string;
    showSpam?: boolean;
}

export interface ListsTextSearchFormResponseData {
    textItemsData: FrequentlyUsedText[];
    userActivityData: UserActivityItem[];
    fetching: boolean;
    error?: AxiosError;
}

export const ListsTextSearchForm: React.VFC = () => {
    const navigate = useNavigate();
    const [inputData, setInputData] = useState<ListsTextSearchFormInput>({
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        showSpam: false
    });
    const [selectedTextItems, setSelectedTextItems] = useState<SelectedTextItems>({});
    const [selectedCustomers, setSelectedCustomers] = useState<SelectedCustomers>({});
    const [responseData, setResponseData] = useState<ListsTextSearchFormResponseData>({
        fetching: false,
        textItemsData: [],
        userActivityData: []
    });
    const [nextToken, setNextToken] = useState<string | null>();
    const [fetchingCustomerIds, setFetchingCustomerIds] = useState<boolean>();

    const onInputChange = (newData: ListsTextSearchFormInput) => {
        setInputData(newData);
        setNextToken(null);
    };

    const buildRequestBody = (withLoadMore: boolean) => {
        const startDate = new Date(inputData.startDate!);
        const tmpEndDate = new Date(inputData.endDate!);
        const endDate = addDays(tmpEndDate, 1);
        const request: ListsTextSearchDetailsRequest = {
            startTime: startDate.getTime(),
            endTime: endDate.getTime(),
            spamFilter: inputData.showSpam ? 'ALL' : 'NON_SPAM'
        };
        if (withLoadMore && nextToken) {
            request.nextToken = nextToken;
        }
        return request;
    };

    const fetchListsTextSearchDetails = async (withLoadMore: boolean) => {
        setResponseData((prevState) => ({ ...prevState, fetching: true }));
        try {
            const response = await TrustAdminApiFactory().listsTextSearchDetails.getListsTextSearchDetails(
                buildRequestBody(withLoadMore)
            );
            const incomingTextItems = response.data.frequentlyUsedText;
            const incomingUserActivity = response.data.userActivity;

            if (!incomingTextItems.length && !incomingUserActivity.length) {
                const notFoundError = {
                    code: '404',
                    message: 'No modified lists found'
                };
                setResponseData((prevState) => ({ ...prevState, error: notFoundError as AxiosError, fetching: false }));
                return;
            }

            if (!withLoadMore) {
                incomingTextItems.sort((a, b) => b.occurrences - a.occurrences);
                incomingUserActivity.sort((a, b) => b.modifiedListCount - a.modifiedListCount);

                setNextToken(response.data.nextToken);
                setSelectedTextItems(
                    incomingTextItems.reduce((dict, item) => {
                        dict[item.text] = false;
                        return dict;
                    }, {})
                );
                setSelectedCustomers(
                    incomingUserActivity.reduce((dict, item) => {
                        dict[item.customerId] = false;
                        return dict;
                    }, {})
                );
                setResponseData({
                    fetching: false,
                    textItemsData: incomingTextItems,
                    userActivityData: incomingUserActivity
                });
                return;
            }

            const allTextItems = [...responseData.textItemsData, ...incomingTextItems];
            const allUserActivity = [...responseData.userActivityData, ...incomingUserActivity];

            const mergedTextItems = allTextItems.reduce((dict, item) => {
                if (item.text in dict) {
                    dict[item.text].occurrences += item.occurrences;
                } else {
                    dict[item.text] = item;
                }
                return dict;
            }, {});
            const textItemsData = Object.keys(mergedTextItems)
                .map((key) => mergedTextItems[key])
                .sort((a, b) => b.occurrences - a.occurrences);

            incomingTextItems.forEach((item) => {
                if (!(item.text in selectedTextItems)) {
                    selectedTextItems[item.text] = false;
                }
            });
            setSelectedTextItems(selectedTextItems);

            const mergedUserActivity = allUserActivity.reduce((dict, item) => {
                if (item.customerId in dict) {
                    dict[item.customerId].modifiedListCount += item.modifiedListCount;
                } else {
                    dict[item.customerId] = item;
                }
                return dict;
            }, {});
            const userActivityData = Object.keys(mergedUserActivity)
                .map((key) => mergedUserActivity[key])
                .sort((a, b) => b.modifiedListCount - a.modifiedListCount);

            incomingUserActivity.forEach((item) => {
                if (!(item.customerId in selectedCustomers)) {
                    selectedCustomers[item.customerId] = false;
                }
            });
            setSelectedCustomers(selectedCustomers);

            setResponseData({ fetching: false, textItemsData: textItemsData, userActivityData: userActivityData });
        } catch (e) {
            setResponseData((prevState) => ({ ...prevState, error: e as AxiosError, fetching: false }));
        }
    };

    const onSubmit = async () => {
        fetchListsTextSearchDetails(false);
    };

    const onLoadMore = async () => {
        fetchListsTextSearchDetails(true);
    };

    const onAnalyzeSelection = async () => {
        setFetchingCustomerIds(true);
        try {
            const customerIds: Set<string> = new Set();
            responseData.textItemsData.forEach((textItem) => {
                if (selectedTextItems[textItem.text]) {
                    textItem.users.forEach(customerIds.add, customerIds);
                }
            });
            responseData.userActivityData.forEach((userActivity) => {
                if (selectedCustomers[userActivity.customerId]) {
                    customerIds.add(userActivity.customerId);
                }
            });

            const response = await TrustAdminApiFactory().listsOwners.getListsOwners({
                ids: [...customerIds]
            });
            setFetchingCustomerIds(false);

            navigate('/lists/results', {
                state: {
                    successMessage: 'Customer and List Ids submitted successfully!',
                    listsOwners: response.data
                }
            });
        } catch (e) {
            setResponseData((prevState) => ({ ...prevState, error: e as AxiosError }));
            setFetchingCustomerIds(false);
        }
    };

    return (
        <ListsTextSearchFormView
            inputData={inputData}
            onInputChange={onInputChange}
            selectedTextItems={selectedTextItems}
            setSelectedTextItems={setSelectedTextItems}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            responseData={responseData}
            onSubmit={onSubmit}
            canLoadMore={nextToken ? true : false}
            onLoadMore={onLoadMore}
            fetchingCustomerIds={fetchingCustomerIds ?? false}
            onAnalyzeSelection={onAnalyzeSelection}
        />
    );
};
