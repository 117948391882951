import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { FeatureType } from '../../../trustAdmin-api/generated-src';
import TrustAdminApiFactory from '../../../trustAdmin-api/TrustAdminApiFactory';
import { validateCustomerId, INVALID_FORM_INPUT_CHARACTERS_REGEX } from '../../../utils/validation';
import { BulkShadowbanFormView } from './BulkShadowbanFormView';

interface BulkUpdateUserStatusFormInput {
    customerIds?: string[];
    shadowbanContext?: string;
    feature?: FeatureType;
}

interface BulkUpdateUserStatusResponseData {
    fetching?: boolean;
    error?: AxiosError;
}

export const BulkShadowbanForm: React.VFC = () => {
    const navigate = useNavigate();
    const [inputData, setInputData] = useState<BulkUpdateUserStatusFormInput>({});
    const [responseData, setResponseData] = useState<BulkUpdateUserStatusResponseData>({ fetching: false });
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const validateFreeText = (customerIds: string[], shadowbanContext: string) =>
        !INVALID_FORM_INPUT_CHARACTERS_REGEX.test(customerIds.join('')) ||
        !INVALID_FORM_INPUT_CHARACTERS_REGEX.test(shadowbanContext);

    const validateInput = (input: BulkUpdateUserStatusFormInput) => {
        if (!input?.customerIds || input.customerIds.length === 0 || !input?.shadowbanContext || !input?.feature) {
            setResponseData({ error: new Error('Please ensure you have entered all inputs.') as AxiosError });
        } else if (validateFreeText(input.customerIds, input.shadowbanContext)) {
            setResponseData({
                error: new Error(
                    'Customer IDs or shadowban context does not match the correct format. Please fix.'
                ) as AxiosError
            });
        } else {
            const invalidCustomerIds: Array<string> = input.customerIds.filter((id) => !validateCustomerId(id));
            if (invalidCustomerIds.length > 0) {
                setResponseData({
                    error: new Error(
                        'The following customer IDs are invalid:\n' + invalidCustomerIds.join('\n')
                    ) as AxiosError
                });
                setIsModalOpen(false);
            } else {
                setResponseData({ error: undefined });
                setIsModalOpen(true);
            }
        }
    };

    const onInputChange = (newData: object) => {
        setInputData({ ...inputData, ...newData });
    };

    const onSubmit = async () => {
        setIsModalOpen(false);
        setResponseData({ fetching: true });
        try {
            await TrustAdminApiFactory().bulkUpdateUserStatus.bulkUpdateUserStatus({
                customerIds: inputData.customerIds!,
                shadowbanContext: inputData.shadowbanContext!,
                feature: FeatureType[inputData.feature!]
            });
            setResponseData({ fetching: false });

            navigate('/bulkShadowban', {
                state: {
                    successMessage:
                        'CustomerIds to shadowban submitted successfully! These will be processed asynchronously.'
                }
            });
        } catch (e) {
            setResponseData({ error: e as AxiosError, fetching: false });
        }
    };

    return (
        <BulkShadowbanFormView
            inputData={inputData}
            onInputChange={onInputChange}
            responseData={responseData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSubmit={onSubmit}
            validateInput={validateInput.bind(this, inputData)}
        />
    );
};
