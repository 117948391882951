import * as React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { BulkShadowbanCreate } from '../../pages/bulkShadowban/BulkShadowbanCreate/BulkShadowbanCreate';
import { BulkShadowbanStatus } from '../../pages/bulkShadowban/BulkShadowbanStatus/BulkShadowbanStatus';
import { ListSpamWorkflowResults } from '../../pages/lists/ManualEntry/ListSpamWorkflowResults/ListSpamWorkflowResults';
import { ListSpamWorkflowSearch } from '../../pages/lists/ManualEntry/ListSpamWorkflowSearch/ListSpamWorkflowSearch';
import { ListsTextSearch } from '../../pages/lists/TextSearch/ListsTextSearch/ListsTextSearch';
import { Home as RatingsHome } from '../../pages/ratings/Home/Home';
import { Rrc } from '../../pages/ratings/Rrc/Rrc';
import { SuspiciousTitles } from '../../pages/ratings/SuspiciousTitles/SuspiciousTitles';
import { Home as ReviewsHome } from '../../pages/reviews/Home/Home';
import { Spam } from '../../pages/reviews/Spam/Spam';
import { RatingsExport } from '../../pages/titleSearch/RatingsExport/RatingsExport';
import { TitleSearch } from '../../pages/titleSearch/TitleSearch/TitleSearch';
import { TitleSearchLayout } from '../../pages/titleSearch/TitleSearchLayout';
import { Layout } from '../Layout';

export const AppNavigator: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route path='ratings'>
                        <Route path='' element={<RatingsHome />} />
                        <Route path='rrc' element={<Rrc />} />
                        <Route path='suspiciousTitles' element={<SuspiciousTitles />} />
                    </Route>
                    <Route path='reviews'>
                        <Route path='' element={<ReviewsHome />} />
                        <Route path='spam' element={<Spam />} />
                    </Route>
                    <Route path='titleSearch' element={<TitleSearchLayout />}>
                        <Route path='' element={<TitleSearch />} />
                        <Route path='ratingsExport' element={<RatingsExport />} />
                    </Route>
                    <Route path='bulkShadowban'>
                        <Route path='' element={<BulkShadowbanStatus />} />
                        <Route path='create' element={<BulkShadowbanCreate />} />
                    </Route>
                    <Route path='lists'>
                        <Route path='manualEntry'>
                            <Route path='' element={<ListSpamWorkflowSearch />} />
                        </Route>
                        <Route path='textSearch'>
                            <Route path='' element={<ListsTextSearch />} />
                        </Route>
                        <Route path='results' element={<ListSpamWorkflowResults />} />
                    </Route>
                </Route>
                <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
        </BrowserRouter>
    );
};
